// 先封裝起來，若有其他操作需求，可直接新增

const cusStorage = {
  prefix: 'nuwa_',
  setItem(key, val, setToSessionStorage) {
    const jsonVal = JSON.stringify(val);
    if (setToSessionStorage) {
      sessionStorage.setItem(`${this.prefix}${key}`, jsonVal);
    } else {
      localStorage.setItem(`${this.prefix}${key}`, jsonVal);
    }
  },
  getItem(key, getFromSessionStorage) {
    let parseVal = null;
    if (getFromSessionStorage) {
      parseVal = sessionStorage.getItem(`${this.prefix}${key}`) || '';
    } else {
      parseVal = localStorage.getItem(`${this.prefix}${key}`) || '';
    }
    if (parseVal === 'undefined') return null;
    return parseVal !== '' ? JSON.parse(parseVal) : null;
  },
  removeItem(key, callback, removeFromSessionStorage) {
    localStorage.removeItem(`${this.prefix}${key}`);

    if (removeFromSessionStorage) {
      sessionStorage.removeItem(`${this.prefix}${key}`);
    } else {
      localStorage.removeItem(`${this.prefix}${key}`);
    }

    if (callback) {
      callback();
    }
  },
  clear(isSessionStorage) {
    if (isSessionStorage) {
      sessionStorage.clear();
    } else {
      localStorage.clear();
    }
  },
};

export default cusStorage;
