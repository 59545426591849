import Vue from 'vue';
import Vuex from 'vuex';
import VueCookies from 'vue-cookies';
import { getLangCode } from '@/utils/getLangCode';

Vue.use(Vuex, VueCookies);

export default new Vuex.Store({
  state: {
    memberInfo: {},
    member: {
      id: null,
      account: null,
      name: null,
      birthday: null,
      api_token: null,
      email: null,
      phone: null,
      is_first: null
    },
    appointmentData: {
      uuid: null,
      item_id: null,
      devision_id: null,
      code: null,
      date: null,
      sid: null,
      shift_code: null,
      clinic: null,
      name: null
    },
    signUpFormData: {
      account: null,
      name: null,
      birthday: null,
      phone: null,
      email: null,
      country_code: null,
      verify_code: null,
      send_way: null
    },
    appointmentComplete: {
      serial_no: null
    },
    socialLightbox: {
      status: false,
      type: 1,
      qrcodeImg: '',
      name: ''
    },
    doctorAds: [],
    banners: [],
    branchs: [],
    doctors: [],
    doctorMapping: {},
    articles: [],
    bodyLock: false,
    lang: null,
    sysVars: [],
    // siteInit: false,
    // langUUID: null,
    cacheDoctorData: {},
    cacheTeamMapping: {},
    cacheNationality: null,
    showVerifyWarning: false,
    verifyWarningData: {},
    showPrivacyWarning: false,
    showMsgPop: false,
    msgInfo: {},
    storyComeFromPath: null,
    isInAppointementProgress: false,
    recordLatestPage: null,
    // cacheI18nContent: {},
    apiI18nMessage: {},
    // 國際醫療中心 第一個選項的slug
    internationFirstSlug: '',
  },
  getters: {
    getMemberAll(state) {
      return state.member;
    },
    getAppointmentData(state) {
      return state.appointmentData;
    },
    getAppointmentComplete(state) {
      return state.appointmentComplete;
    },
    getSignUpFormData(state) {
      return state.signUpFormData;
    },
    getLanguage() {
      return getLangCode();
    },
    // getLangUUID(state) {
    //   return state.langUUID;
    // },
    siteInit(state) {
      const { articles, doctors, branchs } = state;
      return articles && doctors && branchs;
    },
    getArticleTypeLists: (state) => async (type) => state.articles.filter(({ type: t, lang_id: l }) => t === type && l === state.lang),

    getDevisionDoctors: (state) => (classification) => {
      const classificationName = +classification === 1 ? '婦產科' : '中醫科';
      return state.doctors.filter(({ devision }) => devision === classificationName);
    },
    showRegistrationDialog(state) {
      const { registrationDialog = {} } = state.sysVars || {};
      const { status = false } = registrationDialog;
      return status;
    }
    // showCovid19Dialog(state) {
    //   const { covid19Dialog = {} } = state.sysVars || {};
    //   const { status = false } = covid19Dialog;
    //   return status;
    // }
  },
  mutations: {
    setIsInAppointementProgress(state, status) {
      state.isInAppointementProgress = status;
    },
    setRecordLatestPage(state, pageName) {
      state.recordLatestPage = pageName;
    },
    initMeb(state) {
      if (VueCookies.isKey('memberInfo')) {
        state.memberInfo = VueCookies.get('memberInfo');
      }

      if (VueCookies.isKey('id')) {
        state.member.id = VueCookies.get('id');
      }
      if (VueCookies.isKey('account')) {
        state.member.account = VueCookies.get('account');
      }
      if (VueCookies.isKey('name')) {
        state.member.name = VueCookies.get('name');
      }
      if (VueCookies.isKey('birthday')) {
        state.member.birthday = VueCookies.get('birthday');
      }
      if (VueCookies.isKey('api_token')) {
        state.member.api_token = VueCookies.get('api_token');
      }
      if (VueCookies.isKey('email')) {
        state.member.email = VueCookies.get('email');
      }
      if (VueCookies.isKey('phone')) {
        state.member.phone = VueCookies.get('phone');
      }
    },
    logOut(state) {
      state.member.id = null;
      state.member.account = null;
      state.member.name = null;
      state.member.birthday = null;
      state.member.api_token = null;
      state.member.email = null;
      state.member.phone = null;
      state.member.is_first = null;
      VueCookies.remove('id');
      VueCookies.remove('account');
      VueCookies.remove('name');
      VueCookies.remove('birthday');
      VueCookies.remove('api_token');
      VueCookies.remove('email');
      VueCookies.remove('phone');
      VueCookies.remove('is_first');
    },
    logIn(state, data) {
      state.memberInfo = data.member;
      VueCookies.set('memberInfo', data.member);

      // state.member.id = data.member.id;
      ['id', 'account', 'name', 'birthday', 'email', 'phone', 'is_first'].forEach((field) => {
        state.member[field] = data.member[field];
        VueCookies.set(field, data.member[field]);
      });

      if (data.token) {
        state.member.api_token = data.token;
        VueCookies.set('api_token', data.token);
      }
    },
    setToken(state, token) {
      state.member.api_token = token;
      VueCookies.set('api_token', token);
    },
    // setBranchsList(state, data) {
    //   state.branchs = data;
    // },
    // setDoctorsList(state, data) {
    //   state.doctors = data;
    // },
    // setArticlesList(state, data) {
    //   state.articles = data;
    // },
    setAppointmentData(state, data) {
      state.appointmentData = data;
      // state.appointmentData.item_id = data.item_id;
      // state.appointmentData.devision_id = data.devision_id;
      // state.appointmentData.doctor_code = data.doctor_code;
      // state.appointmentData.appointment_day = data.appointment_day;
      // state.appointmentData.sid = data.sid;
      // state.appointmentData.shift_code = data.shift_code;
      // state.appointmentData.clinic = data.clinic;
      // state.appointmentData.doctor_name = data.doctor_name;
    },
    setAppointmentComplete(state, data) {
      state.appointmentComplete = data;
    },
    setSignUpFormData(state, data) {
      state.signUpFormData = data;
      // state.signUpFormData.account = data.account;
      // state.signUpFormData.name = data.name;
      // state.signUpFormData.birthday = data.birthday;
      // state.signUpFormData.phone = data.phone;
      // state.signUpFormData.email = data.email;
      // state.signUpFormData.country_code = data.country_code;
      // state.signUpFormData.verify_code = data.verify_code;
      // state.signUpFormData.send_way = data.send_way;
    },
    clearAppointmentData(state) {
      state.appointmentData = null;
      // state.appointmentData.item_id = null;
      // state.appointmentData.devision_id = null;
      // state.appointmentData.doctor_code = null;
      // state.appointmentData.appointment_day = null;
      // state.appointmentData.sid = null;
      // state.appointmentData.shift_code = null;
      // state.appointmentData.clinic_code = null;
      // state.appointmentData.doctor_name = null;
    },
    clearAppointmentComplete(state) {
      state.appointmentComplete = null;
    },
    setSocialLightbox(state, data) {
      const { status, type, qrcodeImg, name } = data;
      state.socialLightbox.status = status;
      state.socialLightbox.type = type;
      state.socialLightbox.qrcodeImg = qrcodeImg;
      state.socialLightbox.name = name;
    },
    setBodylock(state, data) {
      state.bodyLock = data;
    },
    // setLanguage(state, data) {
    //   state.lang = data;
    // },
    // setArticles(state, data) {
    //   state.articles = data;
    // },

    setData(state, [key, data]) {
      state[key] = data;
    },
    toggleShowVerifyWarning(state, data) {
      const { status, type } = data;
      console.log('type___', type);
      state.showVerifyWarning = status;

      if (type) {
        state.verifyWarningData = data;
      }
    },
    toggleShowPrivacyWarning(state, status) {
      state.showPrivacyWarning = status;
    },
    toggleShowMsg(state, status) {
      state.showMsgPop = status;
    },
    setMsgInfo(state, status) {
      state.msgInfo = status;
    },

    // setLangUUID(state, data) {
    //   state.langUUID = data;
    // },

    setCacheDoctorData(state, data) {
      // console.log('data...', data);

      const lang = getLangCode();

      const doctorsMapping = data.reduce((obj, item) => {
        obj[item.team_type.slug + item.slug + lang] = item;

        return obj;
      }, {});

      const teamMapping = data.reduce((obj, item) => {
        obj[item.team_type.slug] = item.team_type;

        return obj;
      }, {});

      state.cacheDoctorData = doctorsMapping;
      state.cacheTeamMapping = teamMapping;
    },
    setCacheNationality(state, data) {
      // const lang = getLangCode();
      const nationalityMapping = data.reduce((obj, item) => {
        obj[item.id] = item;

        return obj;
      }, {});

      state.cacheNationality = nationalityMapping;
    },
    // setCacheI18nContent(state, data) {
    //   console.log('data', data);
    //   state.cacheI18nContent(data);
    // }
    setStoryComeFromPath(state, path) {
      console.log('path', path);
      state.storyComeFromPath = path;
    },
    setInternationFirstSlug(state, firstSlug) {
      state.internationFirstSlug = firstSlug;
    },
  },
  actions: {
    // initData({ commit }, { lang, branchs, doctors, articles }) {
    //   commit('setLanguage', lang);
    //   commit('setBranchsList', branchs);
    //   commit('setDoctorsList', doctors);
    //   commit('setArticles', articles);
    // },
  },
  modules: {}
});
