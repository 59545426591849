<template>
  <section id="news">
    <div class="w1200 news-container">
      <div class="col35 intro">
        <!-- <h3 class="eng">NUWA News</h3> -->
        <h5 class="txt-bold">
          {{$t('news')}}
          <img class="deco_flower"
            src="@/assets/img/deco_flower.svg"
            alt="">
        </h5>
      </div>
      <div class="col65">
        <ul class="news-list">
          <li v-for="(item, key) in showNews"
            :key="key">
            <router-link :to="`${getUrlLangCode()}${item.link}`">
              <p class="date col10">{{item.date}}</p>
              <!-- // 原本是col15 -->
              <p class="type col25">{{item.type}}</p>
              <p class="context col70">{{item.context}}</p>
            </router-link>
          </li>
        </ul>
        <router-link :to="{
          name: 'News',
          params: {
            tagSlug: 'all',
            page: '1'
          }
        }" class="btn">{{$t('more')}}</router-link>

      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex';
import { getArticlePages } from '@/lib/cakeHttp';
import dayjs from 'dayjs';
import { getUrlLangCode } from '@/utils/getLangCode';
// import { getNewsTags, getNewsByNew } from '@/lib/public';
// import { mapMutations, mapGetters } from 'vuex';

export default {
  name: 'IndexNews',
  data() {
    return {
      getUrlLangCode,
      Classification: [],
      showNews: []
    };
  },
  computed: {
    ...mapState(['lang'])
  },
  methods: {
    async getData(page) {
      getArticlePages(
        {
          page,
          lang_id: this.lang,
          type: 'news',
          limit: 5
        },
        ({ data }) => {
          this.showNews = data.map(({ uuid, slug, cover_path, title, published, category }) => ({
            uuid,
            slug,
            link: `/newsarticle/${slug}`,
            date: dayjs(published).format('YYYY/MM/DD'),
            image: cover_path,
            type: category?.name || '',
            context: title
          }));
        }
      );
    }
  },
  watch: {},
  mounted() {
    this.getData();
  }
};
</script>
