<template>
  <div class="c-unverified">
    <div class="c-unverified__content">
      <div class="warning-wrap">
        <div class="warning">
          <img class="warning__icon" src="@/assets/icons/warning.svg" />
        </div>

        <div class="desc" v-if="type">
          <p class="desc__text">{{ $t('unverified.s1Head') }}{{ showTypeTxt }}{{ $t('unverified.tail') }}</p>
          <p class="desc__text">{{ $t('unverified.s2Head') }}{{ showBtnTxt }}{{ $t('unverified.tail') }}</p>
          <p class="desc__text">{{ $t('unverified.s3') }}</p>
        </div>
        <div class="desc" v-else>
          <p class="desc__text pre">{{ $t('unverified.normal') }}</p>
        </div>
      </div>

      <div class="btn-wrap">
        <re-button round="round" type="border" @click="toMemberPage" :style="{ width: '120px' }">
          {{ $t('goImmediately') }}
        </re-button>
      </div>
    </div>
  </div>
</template>
<script>
import ReButton from '@/components/form/ReButton.vue';

export default {
  name: 'Unverified',
  components: {
    ReButton
  },
  props: {
    type: {
      type: String,
    }
  },
  methods: {
    toMemberPage() {
      this.$router.push({
        name: 'MemberData'
      });

      this.$emit('close');
    }
  },
  computed: {
    showTypeTxt() {
      return this.type === 'email' ? this.$t('form.email') : this.$t('mobile');
    },
    showBtnTxt() {
      return this.type === 'email' ? this.$t('sendEmail') : this.$t('sendSms');
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/other/mixin.scss";
@import "@/assets/scss/other/color.scss";

.c-unverified {
  @include padding(30px);
}

.btn-wrap {
  margin-top: 30px;
  @include flex(center);
}

.warning-wrap {
  @include flex(center, center, column);
}

.warning {
  @include padding(0 0 20px);

  &__icon {
    width: 50px;
  }
}

.desc {
  text-align: center;

  &__text {
    @include font-style($c-black, 16, 400, 1px, 24px);
  }

  .pre {
    white-space: pre-line;
    text-align: left;
  }
}
</style>
