<template>
  <section id="doctors">
    <div class="w1200 container">
      <div class="col80">
        <!-- <h3 class="eng">{{slogan.enSlogan}}</h3> -->
        <h5>
          {{slogan.zhSlogan}}
          <img class="deco_flower"
            src="@/assets/img/deco_flower_white.svg"
            alt="">
        </h5>
      </div>
      <div class="col20">

        <router-link :to="{name: 'Registration'}"
          class="btn btn-white"
          :class="[lang === 'en' ? 'btnEn' : '']">{{$t('reservation')}}</router-link>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'IndexDoctors',
  computed: {
    ...mapState(['lang'])

  },
  data() {
    return {

      slogan: {
        enSlogan: 'Our doctors specialize in you',
        zhSlogan: this.$t('DoctorsSlogan')
      }
    };
  }
};
</script>
