import i18n from '@/i18n';

import bannerKvPic1 from '@/assets/img/About_NUWA/service_1.jpg';
import bannerKvPic2 from '@/assets/img/index/about_fertility.jpg';
import bannerKvPic3 from '@/assets/img/About_NUWA/service_3.jpg';
import ivfPic from '@/assets/img/temp/ivf.jpg';
import tcmPic from '@/assets/img/temp/tcm_update.jpg';
import labsPic from '@/assets/img/index/imglink_1.jpg';
import more01Pic from '@/assets/img/index/about_IVF_Center_bg_new.jpg';
import more02Pic from '@/assets/img/about/more_2_new.jpg';
import more03Pic from '@/assets/img/about/more_3_new.jpg';

export const images = {
  more01Pic,
  more02Pic,
  more03Pic,
  ivfPic,
  tcmPic,
  labsPic,
  bannerKvPic1,
  bannerKvPic2,
  bannerKvPic3,
};

export const selectClinicDateDoctorList = [
  {
    label: i18n.tc('byClinic'),
    icon: 'byClinic',
    value: 1,
  },
  {
    label: i18n.tc('byDate'),
    icon: 'byDate',
    value: 2,
  },
  {
    label: i18n.tc('byDoctor'),
    icon: 'byDoctor',
    value: 3,
  },
];

export const shiftCodes = {
  morning: 1,
  afternoon: 3,
  evening: 4,
  doctorWang: 101,
  doctorHong: 102,
  doctorChen: 103,
};

export const shiftSettings = {
  [shiftCodes.morning]: {
    label: i18n.tc('am'),
    startTime: '08:30',
    endTime: '12:00',
    checkIn: i18n.t('bookingTimeInfo', ['11:00']),
  },
  [shiftCodes.afternoon]: {
    label: i18n.tc('pm'),
    startTime: '14:00',
    endTime: '17:00',
    checkIn: i18n.t('bookingTimeInfo', ['16:00']),
  },
  [shiftCodes.evening]: {
    label: i18n.tc('betweenNight'),
    startTime: '18:00',
    endTime: '21:00',
    checkIn: i18n.t('bookingTimeInfo', ['20:00']),
  },
  [shiftCodes.doctorWang]: {
    label: i18n.tc('betweenNight'),
    startTime: '16:00',
    endTime: '20:00',
    checkIn: i18n.t('bookingTimeInfo', ['19:00']),
  },
  [shiftCodes.doctorHong]: {
    label: i18n.tc('am'),
    startTime: '08:30',
    endTime: '12:00',
    checkIn: i18n.t('bookingTimeInfo', ['11:00']),
  },
  [shiftCodes.doctorChen]: {
    label: i18n.tc('betweenNight'),
    startTime: '16:30',
    endTime: '20:00',
    checkIn: i18n.t('bookingTimeInfo', ['19:00']),
  },
};

export const timeZH = (shfitCode, specialDoctorShiftCode) => {
  const code = specialDoctorShiftCode || shfitCode;

  const { label, startTime, endTime, checkIn } = shiftSettings[code];
  return `${label}(${startTime}-${endTime})${checkIn}`;
};

export const devisionZH = (condition) => {
  let temp = '';
  switch (`${condition}`) {
    // case '1':
    // case 1:
    // case '3':
    // case 3:
    case '婦產科':
      temp = i18n.t('ivf');
      break;
    // case '2':
    // case 2:
    case '中醫科':
      temp = i18n.t('tcm');
      break;
    default:
      temp = null;
      break;
  }
  return temp;
};

export const placeConverter = (doctorClinic) => {
  switch (doctorClinic) {
    case '一診':
      return i18n.t('one');
    case '二診':
      return i18n.t('two');
    case '三診':
      return i18n.t('three');
    case '四診':
      return i18n.t('four');
    case '五診':
      return i18n.t('five');
    case '六診':
      return i18n.t('sex');
    case '七診':
      return i18n.t('seven');
    case '八診':
      return i18n.t('eight');
    case '大安一診':
      return '大安一';
    default:
      return doctorClinic;
  }
};

export const aboutBarTab = [
  {
    tab: 'ivf',
    type: 'procreations',
    label: 'introIvfTreatment',
    clinic: '生殖醫學',
    menuLabel: 'introIvf',
    url: '/aboutinfo/ivf/',
    // kvdata
    banner: {
      titleEn: 'NUWA FERTILITY',
      titleZh: i18n.t('introIvf'),
      backgroundPic: bannerKvPic1,
      bgcolor: '#f8f8f8',
    },
    // intro
    intro: {
      // titleEn: 'Treatment Introduction',
      titleZh: i18n.t('ivfTitle'),
      intro: i18n.t('aboutIvfIntro'),
      items: [],
    },
    doctor: {
      titleEn: 'DOCTOR<br>INTRODUCTION',
      titleZh: i18n.t('ivfTeamsTitle'),
      info: i18n.t('ivfTeamsIntro'),
      link: '/staff/procreations/p1',
      decoPic: ivfPic,
    },
    more: {
      link: '/about/ivf',
      pic: more01Pic,
      titleEn: 'NUWA IVF CENTER',
      titleZh: i18n.t('introIvf'),
      info: i18n.t('aboutIvfIntro'),
    },
  },
  {
    tab: 'tcm',
    type: 'traditionals',
    label: 'introTcmTreatment',
    clinic: '中醫診所',
    menuLabel: 'introTcm',
    url: '/aboutinfo/tcm/',
    banner: {
      titleEn: 'NUWA TCM CENTER',
      titleZh: i18n.t('introTcm'),
      backgroundPic: bannerKvPic2,
      bgcolor: '#f8f8f8',
    },
    intro: {
      //
      titleEn: 'Title',
      titleZh: i18n.t('introTcm'),
      intro: i18n.t('tcmContent'),
      items: [],
    },
    doctor: {
      titleEn: 'DOCTOR<br>INTRODUCTION',
      titleZh: i18n.t('tcmTeamsTitle'),
      info: i18n.t('tcmTeamsIntro'),
      link: '/staff/traditionals/t1',
      decoPic: tcmPic,
    },
    more: {
      link: '/about/tcm',
      pic: more02Pic,
      titleEn: 'NUWA TCM CENTER',
      titleZh: i18n.t('introTcm'),
      info: i18n.t('aboutTcmIntro'),
    },
  },
  {
    tab: 'lab',
    type: 'labs',
    label: 'introlabsTreatment',
    clinic: '胚胎實驗室',
    menuLabel: 'introlabs',
    url: '/aboutinfo/labs/',
    banner: {
      titleEn: 'NUWA LAB',
      titleZh: i18n.t('introlabs'),
      backgroundPic: bannerKvPic3,
      bgcolor: '#f8f8f8',
    },
    intro: {
      titleEn: 'About Lab',
      titleZh: i18n.t('labsTitle'),
      intro: i18n.t('labsIntro'),
      items: [],
    },
    doctor: {
      titleEn: 'DOCTOR<br>INTRODUCTION',
      titleZh: i18n.t('labsTeamsTitle'),
      info: i18n.t('labsTeamsIntro'),
      link: '/staff/labs/l1',
      decoPic: labsPic,
    },
    more: {
      link: '/about/lab',
      pic: more03Pic,
      titleEn: 'NUWA LAB',
      titleZh: i18n.t('introlabs'),
      info: i18n.t('aboutLabsIntro'),
    },
  },
];

export const getAboutBarType = (tabInfo) => {
  const item = aboutBarTab.find(({ tab }) => tabInfo === tab);
  if (!item) {
    console.error('👻 👻  ~ file: const.js ~ line 124 ~ getAboutBarType ~ tab 未定義', tabInfo);
  }
  return item;
};

/**
 * 回傳中文星期
 */
export function weekDay(d) {
  const presentWeekday = new Date(d).getDay();
  // const taiwanDate = new Date(d).toLocaleString('en-US', { timeZone: 'Asia/Taipei' });

  // const presentWeekday = new Date(d).getDay();
  // const presentWeekday = new Date(taiwanDate).getDay();

  return i18n.t(`weekday.${presentWeekday}`);
}

/**
 * 判斷語系
 */
// export const getLang = (langType) => {
//   const lists = [
//     {
//       lang: 'en',
//       id: 'en',
//       value: ['en-'],
//     },
//     {
//       lang: 'zh_CN',
//       id: 'cn',
//       value: ['hans', 'zh-hans-cn', 'zh-hans-hk', 'zh-hans-mo', 'zh-hans-sg', 'zh-hans-hans', 'zh-cn'],
//     },
//     {
//       lang: 'zh_TW',
//       id: 'tw',
//       value: ['zh-tw', 'zh', 'zh-hk', 'zh-mo', 'zh-sg', 'zh-hant-hk', 'zh-hant-mo', 'zh-hant-tw', 'zh-hant'],
//     },
//     {
//       lang: 'ja',
//       id: 'jp', // 就是 jp 不要懷疑！
//       value: ['ja'],
//     },
//   ];
//   const {
//     // lang = 'zh_TW',
//     id = 'tw',
//   } = lists.find(({ value }) => value.includes(langType)) || {};
//   return id;
// };

export const breakpoints = {
  1440: {
    slidesPerView: 5.5,
    spaceBetween: 40,
  },
  1366: {
    slidesPerView: 5,
    spaceBetween: 40,
  },
  1280: {
    slidesPerView: 4,
    spaceBetween: 40,
  },
  992: {
    slidesPerView: 3,
    spaceBetween: 40,
  },
  768: {
    slidesPerView: 4,
    spaceBetween: 40,
  },
  680: {
    slidesPerView: 3,
    spaceBetween: 40,
  },
  520: {
    slidesPerView: 2.7,
    spaceBetween: 40,
  },
  420: {
    slidesPerView: 2,
    spaceBetween: 40,
  },
};

export const formatMetaLists = (metas) => {
  let lists = [
    {
      vmid: 'type',
      name: 'og:type',
      content: 'website',
    },
    {
      vmid: 'description',
      name: 'description',
      content: metas?.description,
    },
    {
      vmid: 'keyword',
      name: 'keyword',
      content: metas?.keyword,
    },
  ];
  const ogMeta = metas?.og;
  // if (navigator.userAgent.indexOf('Line') > -1) {
  //   ogMeta = metas?.line;
  // }
  if (ogMeta) {
    const ogLists = Object.keys(ogMeta)
      .map((key) => {
        let content = ogMeta?.[key];
        if (key === 'image' && content) {
          content = `${process.env.VUE_APP_API_NEW}${content}`;
        }
        return {
          property: `og:${key}`,
          vmid: `og:${key}`,
          content,
        };
      })
      .filter(({ content }) => content);
    lists = [...lists, ...ogLists];
  }
  return lists;
};

export function formatContent(content, $limit = 30, trim = false, more = '') {
  if (!content) return '';
  let tmp = content.replace(/(<([^>]+)>)/gi, '');
  if (trim) {
    tmp = tmp
      .replace(/\r/gi, '')
      .replace(/\n/gi, '')
      .replace(/&nbsp;/gi, '')
      .trim();
  }
  return tmp.substr(0, $limit) + more;
}
