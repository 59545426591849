import { getMetas, getCodes } from '@/lib/cakeHttp';
import { mapState } from 'vuex';
import { formatMetaLists } from '@/utils/const';

const rootMeta = {
  title: '宜蘊醫療',
  description:
    '擁有眾多不孕症權威及萬名試管嬰兒成功經驗醫師群的宜蘊醫療，旗下共有五間診所，包含三間高規格試管嬰兒生殖中心、兩間頂級中醫診所，及三間領先全台技術之胚胎實驗室，分別設立於台北、桃園、台中三地。 以女性為本的宜蘊醫療，主要服務項目為不孕症治療、試管嬰兒、人工受孕、凍卵、借卵跟備孕調理等等，並有完善的女性醫護團隊，讓來診的女性更為自在。在2021年宜蘊醫療正式與禾馨醫療合作，從備孕、懷孕、生產到坐月子，一條龍式整合服務，給來診者最大的便利。',
  keyword:
    '不孕症 試管嬰兒 人工受孕 人工授精 冷凍卵子 凍卵 凍精 捐卵 借卵 生殖醫學 女醫生 女醫師 不孕症 養卵 增厚內膜 胚胎植入 小產 孕期 經痛 過敏體質改善 減重 睡眠障礙 針灸 中藥調理 中醫 女醫師',
};

export default {
  name: 'metaMixin',
  data() {
    return {
      rawData: [],
      testTitle: 'hi',

    };
  },
  metaInfo() {
    return {
      title: this.metaData?.title,
      // title: this.testTitle,
      titleTemplate: '%s | 宜蘊醫療',
      htmlAttrs: {
        lang: this.lang,
      },
      meta: this.infoMeta,
      link: [{ rel: 'canonical', href: window.location.href }],
    };
  },

  computed: {
    ...mapState(['lang']),
    infoMeta() {
      return formatMetaLists(this.metaData);
    },
    metaData() {
      const item = this.rawData.find((data) => this.$route.path === (data.url));

      return item?.metas || rootMeta;
    },
  },
  methods: {
    testHtmlTitle() {
      setTimeout(() => {
        this.testTitle = 'bye';
      }, 3000);
    },
    fetchMetaData() {
      getMetas(this.lang, ({ data }) => {
        this.rawData = data;
      });
      getCodes((/* { data } */) => {
        // this.setScript('head', data);
        // this.setScript('body', data);
      });
    },
    setScript(type, lists) {
      const data = document.querySelector(type);
      const typeScript = lists
        .filter(({ type: t }) => type === t)
        .map(({ content }) => content)
        .join('\n');
      if (data.innerHTML.includes(typeScript)) {
        return;
      }
      data.innerHTML += typeScript;
    },
  },
  mounted() {
    this.fetchMetaData();
    this.testHtmlTitle();
  },
};
