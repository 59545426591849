<template>
  <div class="footer-clinic-address">
    <div class="address-info" v-for="d of clinicData" :key="d.nameI18nKey">
      <div class="address-info__content">
        <div class="name-icons">
          <p class="clinic-name">{{ $t(d.nameI18nKey) }}</p>
        </div>

        <p class="text"> {{ $t("address") }} | {{ $t(d.addressI18nKey) }} </p>
        <p class="text">
          {{ $t("contactText") }} |
          <a v-if="lang === 'zh-tw'" :href="`tel:${d.tel.area}-${d.tel.number}`">({{ d.tel.area }}){{ d.tel.number }}</a>
          <a v-else :href="`tel:${d.tel.foreignNumber}`">{{ d.tel.foreignNumber }}</a>
        </p>
        <p class="text">
          <a
            href="mailto:ivfconsult@nuwacare.com"
          >
          {{ $t("mail") }} | ivfconsult@nuwacare.com
          </a>
        </p>

        <ul class="icons-list">
          <li class="icons-list__item" v-for="(icon, idx) of d.icons" :key="`${d.nameI18nKey + String(idx)}`">
            <a class="icons-list__item__link" v-if="icon.type === 'nativeLink'" :href="icon.link" target="_blank">
              <img class="icons-list__item__link__sns-icon" :src="icon.icon" :alt="icon.iconAlt" />
            </a>

            <span class="icons-list__item__link" v-if="icon.type === 'clickEvent'"
              @click="checkDeviceAndOpen(icon.funcArgs, $t(d.nameI18nKey))">

              <img class="icons-list__item__link__sns-icon" :src="icon.icon" :alt="icon.iconAlt" />
            </span>

          </li>
        </ul>

      </div>

    </div>
  </div>
</template>
<script>
import { isMobile } from 'mobile-device-detect';
import { getLangCode } from '@/utils/getLangCode';
import { mapMutations } from 'vuex';

export default {
  name: 'FooterClinicAddress',
  props: {
    clinicData: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      isMobile,
      ifLightbox1: false,
      ifLightbox2: false,
    };
  },
  methods: {
    ...mapMutations(['setSocialLightbox', 'setBodylock', 'setLanguage']),
    checkDeviceAndOpen(funcArgs, name) {
      const { typeNum, url, qrcodeImg } = funcArgs;

      // if ([1, 2].includes(typeNum)) {
      //   const data = {
      //     status: true,
      //     type: typeNum,
      //     qrcodeImg,
      //     name
      //   };
      //   this.setSocialLightbox(data);
      //   this.setBodylock(true);

      //   return;
      // }

      if (this.isMobile) {
        window.open(url, '_blank');
      } else {
        const data = {
          status: true,
          type: typeNum,
          qrcodeImg,
          name
        };
        this.setSocialLightbox(data);
        this.setBodylock(true);
      }
    },
    openLightbox1() {
      this.ifLightbox1 = true;
      const el = document.getElementsByTagName('body')[0];
      el.classList.add('lock');
    },
    closeLightbox1() {
      this.ifLightbox1 = false;
      const el = document.getElementsByTagName('body')[0];
      el.classList.remove('lock');
    },
    openLightbox2() {
      this.ifLightbox2 = true;
      const el = document.getElementsByTagName('body')[0];
      el.classList.add('lock');
    },
    closeLightbox2() {
      this.ifLightbox2 = false;
      const el = document.getElementsByTagName('body')[0];
      el.classList.remove('lock');
    },

  },
  computed: {
    lang() {
      return getLangCode();
    }
  },
  watch: {
    /** 換路徑即關閉footer lightbox */
    $route() {
      this.ifLightbox1 = false;
      this.ifLightbox2 = false;
    }
  }

};
</script>
<style lang="scss" scoped>
.footer-clinic-address {
  display: flex;
  flex-wrap: wrap;

  @media screen and (max-width: 1280px) {
    margin-top: 20px;
    flex-direction: row;
  }

  @media screen and (max-width: 768px) {
    margin-top: 0px;
    display: block;
  }
}

.address-info {
  // width: 100%;
  margin-top: 30px;
  width: 50%;
  // height: 100px;
  display: flex;
  justify-content: space-between;

  &:nth-child(1),
  &:nth-child(2) {
    margin-top: 0;
  }

  @media screen and (max-width: 1280px) {
    width: 100%;
    height: auto;

    &:nth-child(1),
    &:nth-child(2) {
      margin-top: 10px;
    }
  }

  @media screen and (max-width: 768px) {
    margin-top: 10px;
  }

  .name-icons {
    // display: flex;
    justify-content: space-between;

    // align-items: flex;

    @media screen and (max-width: 1280px) {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      // text-align: center;
    }
  }

  // &+& {
  //   margin-top: 20px;
  // }

  &__content {
    width: 100%;

    @media screen and (max-width: 1280px) {
      margin: 0 auto;
    }
  }
}

.clinic-name {
  line-height: 20px;
  position: relative;
  margin-bottom: 28px;

  &::after {
    content: '';
    position: absolute;
    bottom: -15px;
    left: 0;
    width: 15px;
    height: 1px;
    display: inline-block;
    background-color: #fff;
  }

}

.icons-list {
  display: flex;
  margin-top: 10px;

  @media screen and (max-width: 1280px) {
    margin-top: 10px;
  }

  &__item {
    text-align: center;

    &+& {
      margin-left: 10px;
    }

    &__link {
      width: 25px;

      &__sns-icon {
        width: 25px;
        cursor: pointer;
        vertical-align: bottom;
      }
    }
  }
}

.text {
  font-size: 0.9rem;
  letter-spacing: 0.7px;
  line-height: 1.375rem;

  @media screen and (max-width: 1280px) {
    // text-align: center;
  }

}
</style>
