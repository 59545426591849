<template>
  <div class="c-msg">
    <div class="c-msg__content">
      <div class="status-wrap">
        <div class="status">
          <img class="status__icon" src="@/assets/icons/confirm.svg" v-if="$attrs.succeed"/>
          <img class="status__icon" src="@/assets/icons/warning.svg" v-else/>
        </div>

        <div class="desc">
          <p class="desc__text">{{ $attrs.desc }}</p>
        </div>
      </div>

      <div class="btn-wrap">
        <re-button round="round" type="border" @click="closeDialog" :style="{width: '120px'}">
          {{$t('confirm')}}
        </re-button>
      </div>
    </div>
  </div>
</template>
<script>
import ReButton from '@/components/form/ReButton.vue';

export default {
  name: 'Msg',
  components: {
    ReButton
  },
  props: {
    triggerCallbackOnConfirmBtn: {
      type: Boolean,
      default: false,
    },
    callback: {
      type: [Function, null],
      default: null
    }
  },
  methods: {
    closeDialog() {
      if (this.triggerCallbackOnConfirmBtn) {
        this.callback();
      }

      this.$emit('beforeClose');
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/other/mixin.scss";
@import "@/assets/scss/other/color.scss";

.c-msg {
  @include padding(30px);
}

.btn-wrap {
  margin-top: 30px;
  @include flex(center);
}

.status-wrap {
  @include flex(center, center, column);
}

.status {
  @include padding(0 0 20px);

  &__icon {
    width: 50px;
  }
}

.desc {

  &__text {
    @include font-style($c-black, 16, 400, 1px ,24px);
    text-align: center;
  }
}

</style>
