<template>
  <div class="c-loading" :class="`c-loading--${color}`">
    <div class="loader loader--style2" title="1">
      <svg id="loader-1" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px" y="0px" width="20px" height="20px" viewBox="0 0 50 50" style="enable-background: new 0 0 50 50;"
        xml:space="preserve">
        <path fill="#000"
          d="M25.251,6.461c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615V6.461z">
          <animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 25 25" to="360 25 25"
            dur="1.5s" repeatCount="indefinite" />
        </path>
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: 'theme',
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/other/mixin.scss";
@import "@/assets/scss/other/color.scss";

.c-loading {
  @include flex(center);
  margin: 0 10px;

  svg path,
  svg rect {
    fill: $c-white;
  }

  &--white {

    svg path,
    svg rect {
      fill: $c-white;
    }
  }

  &--theme {

    svg path,
    svg rect {
      fill: $color-theme;
    }
  }
}

.loader {
  display: inline-block;
  text-align: center;
}
</style>
