<template>
  <div class="c-fix-reservation">
    <div class="c-fix-reservation__top" @click="toBooking">
      <p class="booking-text">{{ $t('booking') }}</p>
      <div class="arrow-wrap">
        <img class="arrow-wrap__icon arrow-wrap__icon--rotate" src="@/assets/icons/fix_reservation_down.svg">
      </div>
    </div>
    <div class="c-fix-reservation__bottom" @click="toMyReservation">
      <p class="my-reservation-text">{{ $t('myregistration') }}</p>
      <div class="arrow-wrap">
        <img class="arrow-wrap__icon" src="@/assets/icons/fix_reservation_down_white.svg">
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

export default {
  name: 'FixReservation',
  computed: {
    ...mapGetters(['getMemberAll'])
  },
  methods: {
    ...mapMutations(['setRecordLatestPage']),
    toBooking() {
      this.$router.push({
        name: 'Registration'
      });
    },
    toMyReservation() {
      if (this.getMemberAll.api_token) {
        this.$router.push({
          name: 'MemberHome'
        });
        return;
      }

      this.setRecordLatestPage('MemberHome');
      this.$router.push({
        name: 'Login'
      });
    }
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/other/mixin.scss";
@import "@/assets/scss/other/color.scss";

.c-fix-reservation {
  display: none;
}

@media screen and (min-width: 1366px) {

  .c-fix-reservation {
    position: fixed;
    top: 115px;
    right: 0;
    z-index: 1000;
    box-shadow: 0 0 10px rgba(0, 0, 0, .3);
    border-top-left-radius: 24px;
    border-bottom-left-radius: 24px;
    overflow: hidden;
    display: inline-block;

    &__top {
      @include padding(16px);
      @include flex(center, center, column);
      background-color: $c-white;
      cursor: pointer;
    }

    &__bottom {
      @include padding(16px);
      background-color: $c-schedule-pink;
      @include flex(center, center, column);
      cursor: pointer;
    }
  }

  .booking-text {
    text-align: center;
    width: 20px;
    @include font-style($color-theme, 14, 500, 0.7px);
    // height: 70px;
    writing-mode: vertical-lr;
    text-align: justify;
    text-align-last: justify;
    letter-spacing: 2px;
  }

  .my-reservation-text {
    text-align: center;
    width: 20px;
    @include font-style($c-white, 14, 500, 0.7px);
    // height: 70px;
    text-align: justify;
    text-align-last: justify;
    writing-mode: vertical-lr;
    letter-spacing: 2px
  }

  .arrow-wrap {
    margin-top: 5px;

    &__icon {
      width: 14px;
      transform: rotate(180deg);
    }
  }
}
</style>
