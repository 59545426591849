<template>
  <div id="quickLinks">
    <div class="quickLinks-wrapper">
      <p class="supersmall title">{{ $t('immediateConsult') }}</p>
      <div v-for="(item, k) in links" :key="`ql_${k}`" :class="`quickLinks-icon-${item.name}`" @click="snsLightbox(item)">
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import { isMobile } from 'mobile-device-detect';

import wechat from '@/assets/clinicQrcode/wechat.png';
import whatsapp from '@/assets/clinicQrcode/whatsapp.jpg';

const links = [
  {
    name: 'line',
    type: 0,
    url: 'https://line.me/R/ti/p/%40232ecljp',
  },

  {
    name: 'WeChat',
    type: 1,
    url: 'https://weixin.qq.com/r/zhMAGNnEZIQbrZ1790bc',
    qrcodeImg: wechat
  },
  {
    name: 'whatsApp',
    type: 2,
    url: 'https://api.whatsapp.com/message/7HWY22DTTJNUD1?autoload=1&app_absent=0 ',
    qrcodeImg: whatsapp
  },

];

export default {
  name: 'QuickLinks',
  data() {
    return {
      links,
      isMobile,
    };
  },
  methods: {
    ...mapMutations([
      'setSocialLightbox',
      'setBodylock',
    ]),
    snsLightbox(item) {
      if ((item.type !== 0) && this.isMobile) {
        window.open(item.url, '_blank');
      } else {
        this.setSocialLightbox({ status: true, ...item });
        this.setBodylock(true);
      }
    },
  },
};
</script>
