import Vue from 'vue';
// import { initLanguage } from '@/utils/getLangCode';
// Swiper
import VueAwesomeSwiper from 'vue-awesome-swiper';
import 'swiper/swiper-bundle.css';
// axios
// import axios from 'axios';
// import VueAxios from 'vue-axios';
// sweetalert2
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import VueCookies from 'vue-cookies';
// media query
import VueMq from 'vue-mq';
import VueMeta from 'vue-meta';

// i18n
// font awesome
import { library } from '@fortawesome/fontawesome-svg-core';
import { faFileDownload, fas } from '@fortawesome/free-solid-svg-icons';
import { faPlusSquare } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import ReLoading from '@/components/global/ReLoading.vue';
import Paginate from 'vuejs-paginate';

import setMultipleLanguageLinkForBots from '@/lib/setMultipleLanguageLinkForBots';
import VCalendar from 'v-calendar';
import i18n from '@/i18n';
import App from './App.vue';
import router from './router';
import store from './store';

// initLanguage();

setMultipleLanguageLinkForBots();

console.log('aaa');

Vue.config.productionTip = false;
Vue.use(VueAwesomeSwiper);
// Vue.use(VueAxios, axios);
Vue.use(VueSweetalert2);
Vue.use(VueCookies);
Vue.use(VueMq, {
  breakpoints: {
    mobile: 500,
  },
});
Vue.use(VueMeta);

library.add(faFileDownload, faPlusSquare, fas);
Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('paginate', Paginate);
Vue.component('ReLoading', ReLoading);

Vue.use(VCalendar);

new Vue({
  i18n,
  router,
  store,
  render: (h) => h(App),
  created() {
    // eslint-disable-next-line no-proto
    this.__proto__.$customSWAL = ({
      icon = 'success',
      title = '',
      text = null,
      thenFunc = null,
      confirmButtonText = this.$t('enter'),
      html = null,
    } = {}) => {
      this.$swal
        .fire({
          position: 'center-center',
          icon,
          title: title || this.$t('message'),
          text,
          html,
          confirmButtonText,
          showClass: { popup: 'animated fadeInDown faster' },
          hideClass: { popup: 'animated fadeOutUp faster' },
        })
        .then(() => {
          if (thenFunc) {
            thenFunc();
          }
        });
    };
  },
}).$mount('#app');
