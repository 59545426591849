import cusStorage from '@/utils/cusStorage';

// 整個網頁就這四種語言！(en相關都歸類在en)，其他通通預設 zh-tw
const allowedBrowserLang = ['zh-tw', 'zh-cn', 'en', 'ja'];
// 瀏覽器網址允許的語言
export const allowedBrowserUrlLang = ['zh-TW', 'zh-CN', 'en', 'ja'];

// 拿網頁語言
export const getLangCode = () => {
  const langFromLocalStorage = cusStorage.getItem('lang');

  if (allowedBrowserLang.includes(langFromLocalStorage)) {
    return langFromLocalStorage;
  }

  const browserLang = navigator?.language?.toLocaleLowerCase() || 'tw';

  // 英語系走這 en, en-us, en-gb ... etc.
  if (browserLang.includes('en')) {
    return 'en';
  }

  // 日文
  if (browserLang === 'ja') {
    return 'ja';
  }

  // 對面來的走這
  if (browserLang === 'zh-cn') {
    return 'zh-cn';
  }

  return 'zh-tw';
};

// 打api語言，目前只有日文有差，後端改太麻煩了，所以這裡統一處理
export const getApiLangCode = () => {
  const lang = getLangCode();
  const apiLangCodeMapping = {
    'zh-tw': 'tw',
    'zh-cn': 'cn',
    en: 'en',
    ja: 'jp',
  };

  return apiLangCodeMapping[lang] || 'tw';
};

// 拿網址路徑語言
export const getUrlLangCode = () => {
  const lang = getLangCode();
  const urlLangCodeMapping = {
    'zh-tw': 'zh-TW',
    'zh-cn': 'zh-CN',
    en: 'en',
    ja: 'ja',
  };

  return urlLangCodeMapping[lang] || 'zh-TW';
};

// 切換語言
export const switchLanguage = (lang) => {
  cusStorage.setItem('lang', lang);

  // 設完語言重整，會自動判斷語言重導
  const pagePath = window.location.pathname.replace(/^\/(zh-TW|zh-CN|en|ja)/, '');
  const targetPage = `${window.location.origin}/${getUrlLangCode()}${pagePath}`;
  window.location = targetPage;
};

// 透過網址判斷現在用什麼語言
export const setLanguage = (lang) => {
  const urlLangCodeMapping = {
    'zh-TW': 'zh-tw',
    'zh-CN': 'zh-cn',
    en: 'en',
    ja: 'ja',
  };

  cusStorage.setItem('lang', urlLangCodeMapping[lang]);
};

const getUrlLang = () => {
  const { pathname } = window.location;
  const splitPath = pathname.split('/');

  return splitPath[1];
};

const handleLang = () => {
  const urlLang = getUrlLang();

  if (allowedBrowserUrlLang.includes(urlLang)) {
    setLanguage(urlLang);
  }
};

export const initLanguage = () => {
  handleLang();
  const lang = getLangCode();
  cusStorage.setItem('lang', lang);
};
