var render = function render(){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"re-dropdown-option",class:[
    {
      're-dropdown-option--active': String(_vm.currOption) === String(_vm.value),
      're-dropdown-option--label': _vm.$attrs.type === 'label',
      're-dropdown-option--disabled': _vm.disabled,
    },
  ],attrs:{"data-option-value":_vm.value,"data-disabled-status":_vm.disabled,"data-label":_vm.$attrs.type === 'label',"selected":String(_vm.currOption) === String(_vm.value)},on:{"click":function($event){$event.stopPropagation();return _vm.handleOption(_vm.value, _vm.$attrs.type)}}},[(_vm.$attrs.type === 'label')?[_c('span',{staticClass:"re-dropdown-option__item"},[_vm._v(_vm._s(_vm.label))])]:[(_vm.render)?_c(_vm.render(),_vm._b({tag:"component"},'component',_vm.$attrs,false)):_c('span',{staticClass:"re-dropdown-option__item"},[_vm._v(_vm._s(_vm.label))])]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }