<template>
  <div class="re-dropdown">
    <!-- <font-awesome-icon icon="globe" class="globe-icon" /> -->
    <span class="lang-text">
      {{$attrs.renderData.label}}
    </span>
  </div>
</template>
<script>

export default {
  name: 'LangOption',
};
</script>
<style lang="scss" scoped>
.globe-icon {
  font-size: 12px;
}
.lang-text {
  font-size: 14px;
  margin-left: 5px;
  display: inline-block;
}
</style>
