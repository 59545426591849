<template>
  <div id="app">
    <!-- test -->
    <SocialLightbox />
    <Header :indexHeader="indexHeader" :firstServerSlug="firstServerSlug" />
    <transition enter-active-class="page-fade-enter-active" leave-active-class="page-fade-leave-active">
      <router-view />
    </transition>
    <Footer />
    <QuickLinks />

    <!-- email 或 手機 未驗證 -->
    <re-dialog :visible.sync="unverifiedVisible" footerPosition="center" :template="Unverified" :data="verifyWarningData"
      :triggerCallbackOnOverlay="verifyWarningData.triggerCallbackOnOverlay ? true : false"
      :callback="verifyWarningData.callback || null" />

    <!-- 隱私權 -->
    <re-dialog :visible.sync="privacyVisible" footerPosition="center" :template="Privacy"
      @beforeClose="closePrivacyVisible" />
    <!-- prohibitCloseOnOverlay -->

    <!-- 純訊息 -->
    <re-dialog :visible.sync="msgVisible" footerPosition="center" :data="msgInfo" @beforeClose="closeMsg" :template="Msg"
      :callback="msgInfo.callback || null" :triggerCallbackOnOverlay="msgInfo.triggerCallbackOnOverlay || false" />

    <fix-reservation />

  </div>
</template>

<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import QuickLinks from '@/components/QuickLinks.vue';
import SocialLightbox from '@/components/SocialLightbox.vue';
import { getBranchs, getDoctors, getArticles, getSysVars, getDoctorAds, initLanguages, getServeList } from '@/lib/cakeHttp';
import ReDialog from '@/components/dialog/ReDialog.vue';
// import EmailUnverified from '@/components/dialog/content/EmailUnverified.vue';
import Privacy from '@/components/dialog/content/Privacy.vue';
import { mapGetters, mapMutations, mapState } from 'vuex';
import '@/assets/scss/main.scss';
import '@/assets/scss/public.scss';
// import { getLang } from '@/utils/const';
import { getApiLangCode, getLangCode } from '@/utils/getLangCode';
import Msg from '@/components/dialog/content/Msg.vue';
import Unverified from '@/components/dialog/content/Unverified.vue';
import FixReservation from '@/components/global/FixReservation.vue';
import appMeta from './appMeta';

export default {
  name: 'App',
  mixins: [appMeta],
  components: {
    Header,
    Footer,
    QuickLinks,
    SocialLightbox,
    ReDialog,
    FixReservation
  },
  data() {
    return {
      Unverified,
      // EmailUnverified,
      Privacy,
      Msg,
      indexHeader: true,
      sociallightboxOpen: false,
      socialItem: 1,
      unverifiedVisible: false,
      privacyVisible: false,
      msgVisible: false,
      firstServerSlug: '',
    };
  },
  computed: {
    ...mapState(['showVerifyWarning', 'showPrivacyWarning', 'showMsgPop', 'msgInfo', 'verifyWarningData']),
    ...mapGetters(['getLanguage'])
  },
  methods: {
    ...mapMutations(['initMeb', 'toggleShowVerifyWarning', 'toggleShowPrivacyWarning', 'toggleShowMsg']),
    // , 'setLanguage', 'setBranchsList', 'setDoctorsList', 'setArticles']),
    ...mapMutations({
      commitData: 'setData'
    }),

    closePrivacyVisible() {
      this.privacyVisible = false;
    },
    closeMsg() {
      this.msgVisible = false;
    },
    changeHeaderClass() {
      if (this.$route.path === '/') {
        this.indexHeader = true;
      } else {
        this.indexHeader = false;
      }
    },
    clickCloseLightbox() {
      this.sociallightboxOpen = false;
      const el = document.getElementsByTagName('body')[0];
      el.classList.remove('lock');
    },
    clickOpenLightbox(type) {
      this.sociallightboxOpen = true;
      this.socialItem = type;
      const el = document.getElementsByTagName('body')[0];
      el.classList.add('lock');
    },
    languagesProcess() {
      this.commitData(['lang', getApiLangCode()]);

      this.getData();
    },
    getData() {
      // 取得診所列表
      getBranchs((result) => {
        this.commitData(['branchs', result]);
        // this.setBranchsList(result);
      });
      getDoctorAds((result) => {
        this.commitData(['doctorAds', result.data]);
      });
      // 取得醫師列表
      getDoctors({}, (result) => {
        const doctorMapping = result.reduce((obj, item) => {
          item.child.forEach((doctorId) => {
            obj[String(doctorId)] = item;
          });

          return obj;
        }, {});

        this.commitData(['doctors', result]);
        this.commitData(['doctorMapping', doctorMapping]);
        // this.setDoctorsList(result);
      });
      getArticles({ lang_id: this.lang }, ({ data }) => {
        this.commitData(['articles', data]);
        // this.setArticles(data);
      });

      getSysVars(({ data }) => {
        this.commitData(['sysVars', data]);
        // this.setArticles(data);
      });
      initLanguages(({ data }) => {
        this.commitData(['apiI18nMessage', data]);
      });
      getServeList({ lang_id: this.lang }, ({ data }) => {
        this.firstServerSlug = data[0]?.slug;
        this.commitData(['articles', data]);
        // this.setArticles(data);
      });
    }
  },
  created() {
    // 發布檢查 改用 html-webpack-plugin 在打包時插入 version
    // console.info(
    //   '%c build ',
    //   'background-color:#E94057;color:#fff',
    //   process.env.VUE_APP_VERSION_TAG
    // );
    /* 測試站檔ip */
    // const frontUrl = 'https://www.nuwacare.com/';
    // Axios.get('https://api.ipify.org?format=json')
    //   .then((res) => {
    //     if (res.status) {
    //       if (res.data.ip !== '114.34.52.209') {
    //         window.location.replace(frontUrl);
    //       }
    //     }
    //   }).catch(() => {
    //     window.location.replace(frontUrl);
    //   });
    /* 測試站檔ip */
    this.languagesProcess(getLangCode());
    this.initMeb();
  },
  mounted() {
    this.changeHeaderClass();
  },
  updated() {
    this.changeHeaderClass();
  },
  watch: {
    // email verify warning dialog
    unverifiedVisible(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.toggleShowVerifyWarning({ status: newVal });
      }
    },
    showVerifyWarning(newVal, oldVal) {
      console.log('newVal', newVal);

      if (newVal !== oldVal) {
        this.unverifiedVisible = newVal;
      }
    },
    // privacy warning dialog
    privacyVisible(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.toggleShowPrivacyWarning(newVal);
      }
    },
    showPrivacyWarning(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.privacyVisible = newVal;
      }
    },
    // msg warning dialog
    msgVisible(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.toggleShowMsg(newVal);
      }
    },
    showMsgPop(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.msgVisible = newVal;
      }
    }
  }
};
</script>
