<template>
  <div id="privacy_lightbox2" class="privacy_only">
    <div class="cover" @click="close"></div>
    <div class="box">
      <img src="@/assets/img/delete.svg" class="close" @click="close" />

      <div class="warning-wrap">
        <div class="warning-wrap__content">
          <div v-if="apiI18nMessage && apiI18nMessage.privacyPolicy" v-html="apiI18nMessage.privacyPolicy"/>
        </div>
      </div>

      <slot name="footer"></slot>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';

export default {
  name: 'PrivacyPopup',
  methods: {
    close() {
      this.$emit('close');
    }
  },
  computed: {
    ...mapState(['apiI18nMessage']),
  },
};
</script>
<style lang="scss" scoped>
#privacy_lightbox2 {
  display: block;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 12;
  &.privacy_only {
    .box {
      @media screen and (max-width: 768px) {
        width: 100vw;
      }
    }
  }
  .cover {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
  }
  .box {
    padding: 60px;
    width: 90%;
    max-width: 1100px;
    max-height: 610px;
    // display: flex;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 10px;
    background-color: #f8f8f8;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
    overflow: auto;
    .close {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 1;
      cursor: pointer;
    }
    .privacy_box {
      .privacy_title {
        padding-top: 20px;
      }
    }
  }
  @media screen and (max-width: 768px) {
    .box {
      max-height: 70vh;
      flex-wrap: wrap;
      padding: 10%;
      .text {
        max-height: calc(90vh - 110px);
      }
      .sec {
        .inner {
          flex-wrap: wrap;
          .col40,
          .col60 {
            width: 100%;
          }
          .col40 {
            margin-bottom: 10px;
          }
        }
      }
      .tri {
        .stepouter {
          flex-wrap: wrap;
          .pic {
            order: 2;
            width: 100%;
            padding-bottom: 50%;
          }
          .stepbox {
            order: 1;
            width: 100%;
            .step {
              margin: 10px 0;
              &:nth-child(1) {
                margin-top: 10px;
              }
              &:nth-last-child(1) {
                margin-bottom: 10px;
              }
            }
          }
        }
      }
    }
  }
}

.warning-wrap {
  &__content {
    color: #000;
  }
}
</style>
