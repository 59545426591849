import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { getLangCode, initLanguage } from '@/utils/getLangCode';
import { zhTw as zhTW, enUs as enUS, zhHans, jaJp } from '@nuwa/i18n-content';

initLanguage();

Vue.use(VueI18n);

const i18nMessages = {
  'zh-tw': zhTW,
  'zh-cn': zhHans,
  en: enUS,
  ja: jaJp,
};

const lang = getLangCode();
const i18n = new VueI18n({
  locale: lang,
  messages: i18nMessages,
});

export { i18nMessages };
export default i18n;
