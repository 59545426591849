<template>
  <section id="about">

    <div class="about_bar w1400">

      <div class="about_bar-pagination">
        <div v-for="(tab, t) in aboutBarTab" :key="t" style="text-align: left; padding-left: 30px;"
          :class="nowAboutBarActiveClass(tab.tab)" @click="() => nowAboutBar = tab.tab">
          {{ $t(tab.label) }}
        </div>
      </div>

      <div class="swiper-button-prev"></div>
      <swiper class="about_bar-content" :options="swiperOption">
        <swiper-slide class="item" v-for="(item, key) in aboutBar[nowAboutBar]" :key="key">
          <router-link class="item-wrap" :to="`/${getUrlLangCode()}${item[0]}?sub=0`">
            <div class="img_outer">
              <img :src="item[2]" alt="" />
            </div>
            <p class="txt-dark_gray txt-medium service-text" v-html="item[1]"></p>
          </router-link>
        </swiper-slide>
      </swiper>
      <div class="swiper-button-next"></div>

    </div>

    <div class="about_intro w1200">
      <div class="col50">
        <!-- <h3 class="eng">About NUWA</h3> -->
        <h5>
          {{ $t('aboutNuwaPublic') }}
          <img class="deco_flower" src="@/assets/img/deco_flower_white.svg" alt="">
        </h5>
      </div>
      <div class="col50">
        <p class="small">
          {{ aboutIntroText }}
        </p>
      </div>
    </div>

    <div class="about_content">
      <router-link :to="`/${item.path}`" class="about_content_item" v-for="(item, key) in aboutContent" :key="key"
        :style="{ backgroundImage: `url(${item.backgroundImage})` }">
        <div class="about_content_item-cover"></div>

        <div class="about_content_item-container">
          <div class="about_content_item-title">
            <h5 class="eng">{{ item.zh }}</h5>
            <p v-if="lang !== 'en'">{{ item.en }}</p>
            <div class="about_content_item-content">
              <div class="about_content_item-content-hr"></div>
              <p class="small">{{ item.content }}</p>
              <div class="btn btn-solid">
                <p class="py-2">{{ $t('introTeams') }}</p>
              </div>
            </div>
          </div>
        </div>
      </router-link>
    </div>
  </section>
</template>

<script>
// import { getArticles } from '@/lib/cakeHttp';
import { mapGetters, mapState } from 'vuex';
import { breakpoints, aboutBarTab } from '@/utils/const';

import img1 from '@/assets/img/index/about_IVF_Center_bg_new.jpg';
import img2 from '@/assets/img/index/about_fertility_new.jpg';
import img3 from '@/assets/img/index/about_Lab_bg_new.jpg';
import { getUrlLangCode } from '@/utils/getLangCode';

export default {
  name: 'IndexAbout',
  data() {
    return {
      getUrlLangCode,
      swiperOption: {
        loop: false,
        slidesPerView: 5,
        spaceBetween: 0,
        allowTouchMove: true,
        navigation: {
          nextEl: '#about .swiper-button-next',
          prevEl: '#about .swiper-button-prev'
        },
        // pagination: {
        //   el: '#kv .swiper-pagination',
        //   clickable: true,
        // },
        breakpoints
      },
      aboutBarTab,
      // aboutBarTab: [
      //   {
      //     tab: 'ivf',
      //     label: 'introIvf'
      //   },
      //   {
      //     tab: 'tcm',
      //     label: 'introTcm'
      //   },
      //   {
      //     tab: 'labs',
      //     label: 'introlabs'
      //   }
      // ],
      nowAboutBar: 'ivf',
      aboutBar: {
        ivf: [],
        tcm: [],
        labs: []
      },
      aboutIntroText: this.$t('aboutIntroText'),
      aboutContent: [
        {
          path: `${getUrlLangCode()}/about/ivf`,
          backgroundImage: img1,
          en: 'NUWA FERTILITY',
          zh: this.$t('nuwaIvf'),
          content: this.$t('ivfContent')
        },
        {
          path: `${getUrlLangCode()}/about/tcm`,
          backgroundImage: img2,
          en: 'NUWA TCM CENTER',
          zh: this.$t('nuwaTcm'),
          content: this.$t('tcmContent')
        },
        {
          path: `${getUrlLangCode()}/about/lab`,
          backgroundImage: img3,
          en: 'NUWA LAB',
          zh: this.$t('nuwaLabs'),
          content: this.$t('labsContent')
        }
      ]
    };
  },
  computed: {
    ...mapState(['lang', 'articles']),
    ...mapGetters(['siteInit', 'getArticleTypeLists'])
  },
  watch: {
    articles: {
      handler(val) {
        if (val.length > 0) {
          this.initAboutBar();
        }
      },
      immediate: true
    }
  },
  methods: {
    nowAboutBarActiveClass(tag) {
      return [{ active: this.nowAboutBar === tag }, { en: this.lang === 'en' }];
    },
    initAboutBar() {
      const formatAboutBar = (url) => ({ slug, title, cover_path: icon }) => [`${url}${slug}`, title, icon];
      this.aboutBarTab.forEach(async ({ tab, type }) => {
        this.aboutBar[tab] = [];
        const data = await this.getArticleTypeLists(type);
        this.aboutBar[tab] = data.map(formatAboutBar(`/aboutinfo/${tab}/`));
      });
    }
  },
  mounted() {
    this.initAboutBar();
  }
};
</script>

<style lang="scss" scoped>
.item-wrap {
  width: 220px;

}

.service-text {
  word-break: normal;
  white-space: normal;
  box-sizing: border-box;
  padding: 0 10px;

}

.py-2{
  padding: 6px 0;
}
</style>
