// 在 head 插入一些link 讓 bot 判讀
const setMultipleLanguageLinkForBots = () => {
  const headEle = document.querySelector('head');
  const headEleFirstChild = headEle.firstChild;

  const langs = ['zh-TW', 'zh-CN', 'en', 'ja', ''];

  langs.forEach((l) => {
    const linkEle = document.createElement('link');

    linkEle.setAttribute('rel', 'alternate');
    if (l === '') {
      linkEle.setAttribute('hrefLang', 'x-default');
      linkEle.setAttribute('href', `${window.location.origin}`);
    } else {
      linkEle.setAttribute('hrefLang', l);
      linkEle.setAttribute('href', `${window.location.origin}/${l}`);
    }

    headEle.insertBefore(linkEle, headEleFirstChild);
  });
};

export default setMultipleLanguageLinkForBots;
