import fbIcon from '@/assets/img/communication_1.svg';
import lineIcon from '@/assets/img/communication_2.svg';
import whatsAppIcon from '@/assets/img/communication_3.svg';
import wechatIcon from '@/assets/img/communication_4.svg';

import wechat from '@/assets/clinicQrcode/wechat.png';
import whatsapp from '@/assets/clinicQrcode/whatsapp.jpg';
import taipeiReproduce from '@/assets/clinicQrcode/taipeiReproduce.png';
import taipeChineseMedical from '@/assets/clinicQrcode/taipeChineseMedical.png';
import taoyuanReproduce from '@/assets/clinicQrcode/taoyuanReproduce.png';
import taoyuanChineseMedical from '@/assets/clinicQrcode/taoyuanChineseMedical.png';
import taichungReproduce from '@/assets/clinicQrcode/taichungReproduce.png';
import taichungChineseMedical from '@/assets/clinicQrcode/taichungChineseMedical.png';

// 地址的歷史：
// 原本是兩個兩個垂直並排（按照縣市），所以先有了三個群組
// 然後變成橫的
// 再然後少了桃園中醫
// 為了保持彈性，所以最後才會用 [...a, ...b, ...c] 組合
// 再增加各種 function ...
const allClinics = {
  // 台北生殖
  taipeiIvf: {
    clinicKey: 'taipeiIvf',
    nameI18nKey: 'traffic.taipeiIvf.name',
    icons: [
      {
        type: 'nativeLink', // a 連結
        link: 'https://www.facebook.com/%E5%AE%9C%E8%98%8A%E5%A9%A6%E7%94%A2%E7%A7%91%E8%A8%BA%E6%89%80-%E7%94%9F%E6%AE%96%E9%86%AB%E5%AD%B8-105335891590657/',
        icon: fbIcon,
        iconAlt: 'nuwa facebook',
      },
      {
        type: 'clickEvent', // click 事件
        icon: lineIcon,
        iconAlt: 'nuwa line',
        funcArgs: {
          typeNum: 3,
          // url: 'https://line.me/R/ti/p/%40232ecljp',
          url: 'https://page.line.me/?accountId=nuwacare',
          qrcodeImg: taipeiReproduce,
        }
      },
      {
        type: 'clickEvent',
        icon: whatsAppIcon,
        iconAlt: 'nuwa whatsapp',
        funcArgs: {
          typeNum: 2,
          url: 'https://api.whatsapp.com/message/7HWY22DTTJNUD1?autoload=1&app_absent=0',
          qrcodeImg: whatsapp,
        }
      },
      {
        type: 'clickEvent',
        icon: wechatIcon,
        iconAlt: 'nuwa wechat',
        funcArgs: {
          typeNum: 1,
          url: 'http://weixin.qq.com/r/zhMAGNnEZIQbrZ1790bc',
          qrcodeImg: wechat
        }
      }
    ],
    addressI18nKey: 'traffic.taipeiIvf.address',
    tel: {
      area: '02',
      number: '2706-5288',
      foreignNumber: '+886 2 2706-5288'
    }
  },
  // 台北中醫
  taipeiTcm: {
    clinicKey: 'taipeiTcm',
    nameI18nKey: 'traffic.taipeiTcm.name',
    icons: [
      {
        type: 'nativeLink', // a 連結
        link: 'https://www.facebook.com/nuwatcm',
        icon: fbIcon,
        iconAlt: 'nuwa facebook',
      },
      {
        type: 'clickEvent', // click 事件
        icon: lineIcon,
        iconAlt: 'nuwa line',
        funcArgs: {
          typeNum: 3,
          url: 'https://page.line.me/?accountId=nuwatcm',
          qrcodeImg: taipeChineseMedical,
        }
      },
      {
        type: 'clickEvent',
        icon: whatsAppIcon,
        iconAlt: 'nuwa whatsapp',
        funcArgs: {
          typeNum: 2,
          url: 'https://api.whatsapp.com/message/7HWY22DTTJNUD1?autoload=1&app_absent=0',
          qrcodeImg: whatsapp,
        }
      },
      {
        type: 'clickEvent',
        icon: wechatIcon,
        iconAlt: 'nuwa wechat',
        funcArgs: {
          typeNum: 1,
          url: 'http://weixin.qq.com/r/zhMAGNnEZIQbrZ1790bc',
          qrcodeImg: wechat
        }
      }
    ],
    addressI18nKey: 'traffic.taipeiTcm.address',
    tel: {
      area: '02',
      number: '2706-5277',
      foreignNumber: '+886 2 2706-5277'
    }
  },
  // 桃園生殖
  taoyuanIvf: {
    clinicKey: 'taoyuanIvf',
    nameI18nKey: 'traffic.taoyuanIvf.name',
    icons: [
      {
        type: 'nativeLink', // a 連結
        link: 'https://www.facebook.com/%E5%AE%9C%E8%98%8A%E5%A9%A6%E7%94%A2%E7%A7%91%E8%A8%BA%E6%89%80-%E7%94%9F%E6%AE%96%E9%86%AB%E5%AD%B8-105335891590657/',
        icon: fbIcon,
        iconAlt: 'nuwa facebook',
      },
      {
        type: 'clickEvent', // click 事件
        icon: lineIcon,
        iconAlt: 'nuwa line',
        funcArgs: {
          typeNum: 3,
          url: 'https://liff.line.me/1645278921-kWRPP32q/?accountId=nuwaivfty',
          qrcodeImg: taoyuanReproduce,
        }
      },
      {
        type: 'clickEvent',
        icon: whatsAppIcon,
        iconAlt: 'nuwa whatsapp',
        funcArgs: {
          typeNum: 2,
          url: 'https://api.whatsapp.com/message/7HWY22DTTJNUD1?autoload=1&app_absent=0',
          qrcodeImg: whatsapp,
        }
      },
      {
        type: 'clickEvent',
        icon: wechatIcon,
        iconAlt: 'nuwa wechat',
        funcArgs: {
          typeNum: 1,
          url: 'http://weixin.qq.com/r/zhMAGNnEZIQbrZ1790bc',
          qrcodeImg: wechat
        }
      }
    ],
    addressI18nKey: 'traffic.taoyuanIvf.address',
    tel: {
      area: '03',
      number: '316-6288',
      foreignNumber: '+886 3 316-6288'
    }
  },
  // 桃園中醫
  taiyuanTcm: {
    clinicKey: 'taoyuanTcm',
    nameI18nKey: 'traffic.taoyuanTcm.name',
    icons: [
      {
        type: 'nativeLink', // a 連結
        link: 'https://www.facebook.com/nuwatcm',
        icon: fbIcon,
        iconAlt: 'nuwa facebook',
      },
      {
        type: 'clickEvent', // click 事件
        icon: lineIcon,
        iconAlt: 'nuwa line',
        funcArgs: {
          typeNum: 3,
          url: 'https://liff.line.me/1645278921-kWRPP32q/?accountId=nuwatcmty',
          qrcodeImg: taoyuanChineseMedical,
        }
      },
      {
        type: 'clickEvent',
        icon: whatsAppIcon,
        iconAlt: 'nuwa whatsapp',
        funcArgs: {
          typeNum: 2,
          url: 'https://api.whatsapp.com/message/7HWY22DTTJNUD1?autoload=1&app_absent=0',
          qrcodeImg: whatsapp,
        }
      },
      {
        type: 'clickEvent',
        icon: wechatIcon,
        iconAlt: 'nuwa wechat',
        funcArgs: {
          typeNum: 1,
          url: 'http://weixin.qq.com/r/zhMAGNnEZIQbrZ1790bc',
          qrcodeImg: wechat
        }
      }
    ],
    addressI18nKey: 'traffic.taoyuanTcm.address',
    tel: {
      area: '03',
      number: '316-0638',
      foreignNumber: '+886 3 316-0638'
    }
  },
  // 台中生殖
  taichungIvf: {
    clinicKey: 'taichungIvf',
    nameI18nKey: 'traffic.taichungIvf.name',
    icons: [
      {
        type: 'nativeLink', // a 連結
        link: 'https://www.facebook.com/%E5%AE%9C%E8%98%8A%E5%A9%A6%E7%94%A2%E7%A7%91%E8%A8%BA%E6%89%80-%E7%94%9F%E6%AE%96%E9%86%AB%E5%AD%B8-105335891590657/',
        icon: fbIcon,
        iconAlt: 'nuwa facebook',
      },
      {
        type: 'clickEvent', // click 事件
        icon: lineIcon,
        iconAlt: 'nuwa line',
        funcArgs: {
          typeNum: 3,
          url: 'https://liff.line.me/1645278921-kWRPP32q/?accountId=nuwaivftc',
          qrcodeImg: taichungReproduce,
        }
      },
      {
        type: 'clickEvent',
        icon: whatsAppIcon,
        iconAlt: 'nuwa whatsapp',
        funcArgs: {
          typeNum: 2,
          url: 'https://api.whatsapp.com/message/7HWY22DTTJNUD1?autoload=1&app_absent=0',
          qrcodeImg: whatsapp,
        }
      },
      {
        type: 'clickEvent',
        icon: wechatIcon,
        iconAlt: 'nuwa wechat',
        funcArgs: {
          typeNum: 1,
          url: 'http://weixin.qq.com/r/zhMAGNnEZIQbrZ1790bc',
          qrcodeImg: wechat
        }
      }
    ],
    addressI18nKey: 'traffic.taichungIvf.address',
    tel: {
      area: '04',
      number: '2255-7688',
      foreignNumber: '+886 4 2255-7688'
    }
  },
  // 台中中醫
  taichungTcm: {
    clinicKey: 'taichungTcm',
    nameI18nKey: 'traffic.taichungTcm.name',
    icons: [
      {
        type: 'nativeLink', // a 連結
        link: 'https://www.facebook.com/nuwatcm',
        icon: fbIcon,
        iconAlt: 'nuwa facebook',
      },
      {
        type: 'clickEvent', // click 事件
        icon: lineIcon,
        iconAlt: 'nuwa line',
        funcArgs: {
          typeNum: 3,
          url: 'https://liff.line.me/1645278921-kWRPP32q/?accountId=nuwatcmtc',
          qrcodeImg: taichungChineseMedical,
        }
      },
      {
        type: 'clickEvent',
        icon: whatsAppIcon,
        iconAlt: 'nuwa whatsapp',
        funcArgs: {
          typeNum: 2,
          url: 'https://api.whatsapp.com/message/7HWY22DTTJNUD1?autoload=1&app_absent=0',
          qrcodeImg: whatsapp,
        }
      },
      {
        type: 'clickEvent',
        icon: wechatIcon,
        iconAlt: 'nuwa wechat',
        funcArgs: {
          typeNum: 1,
          url: 'http://weixin.qq.com/r/zhMAGNnEZIQbrZ1790bc',
          qrcodeImg: wechat
        }
      }
    ],
    addressI18nKey: 'traffic.taichungTcm.address',
    tel: {
      area: '04',
      number: '2250-0656',
      foreignNumber: '+886 4 2250-0656'
    }
  },
};

export const taipeiClinicAddress = [
  // 台北生殖
  allClinics.taipeiIvf,
  // 台北中醫
  allClinics.taipeiTcm
];

export const taoyuanClinicAddress = [
  // 桃園生殖
  allClinics.taoyuanIvf,
  // 桃園中醫
  allClinics.taiyuanTcm
];

export const taichungClinicAddress = [
  // 台中生殖
  allClinics.taichungIvf,
  // 台中中醫
  allClinics.taichungTcm
];

const clinicAddress = [
  ...taipeiClinicAddress,
  ...taoyuanClinicAddress,
  ...taichungClinicAddress
];

// 按照台北、桃園、台中順序排除不要的診所
export const getClinicExcludes = (excludesClinicList) => clinicAddress.filter((item) => !excludesClinicList.includes(item.clinicKey));
// 自訂要顯示的診所
export const selectClinics = (clinicList) => clinicList.reduce((list, clinicKey) => {
    list.push(allClinics[clinicKey]);
    return list;
  }, []);

export default clinicAddress;
