<template>
  <div class="socialLightbox" v-show="socialLightbox.status">
    <div class="cover" @click="clickCloseLightbox()"></div>
    <div class="box">
      <!-- 原始關閉圖案是放在圖片裡面, 因此只有click method -->
      <div v-if="socialLightbox.type !== 0" class="fake_close_btn close" @click="clickCloseLightbox()"></div>
      <!-- line qr code頁面是切版, 因此close btn會是獨立的 -->
      <img v-else src="@/assets/img/delete.svg" class="fake_close_btn close closeLine" @click="clickCloseLightbox()" />
      <template v-if="socialLightbox.type === 0">
        <div class="txt-center line_box">
          <h4 class="txt-bold qr_title">
            {{ $t('addLineFriendByQrcode') }}
            <img src="@/assets/img/deco_flower.svg" alt="" class="deco_flower flower_scale" />
          </h4>

          <br />

          <div class="line-qrcode-list-wrap line-qrcode-list-wrap--pc pc-show">
            <ul class="line-qrcode-list">
              <li class="line-qrcode-list__item" :class="{
                'line-qrcode-list__item--active': currClinic.name === addr.nameI18nKey
              }" v-for="addr of clincAddressReOrder" :key="addr.nameI18nKey">
                <div class="curr-clinic curr-clinic--pc">
                  <img class="curr-clinic__qrcode" :src="addr.icons[1].funcArgs.qrcodeImg" />
                  <p class="curr-clinic__name">{{ $t(addr.nameI18nKey) }} </p>
                </div>
              </li>
            </ul>
          </div>

          <div class="mobile-show">
            <!-- <p>請點選欲加入Line好友診所</p> -->

            <div class="line-qrcode-list-wrap">
              <ul class="line-qrcode-list">
                <li class="line-qrcode-list__item" :class="{
                  'line-qrcode-list__item--active': currClinic.name === addr.nameI18nKey
                }" v-for="addr of clincAddressReOrder" :key="addr.nameI18nKey" @click="handleClinic(addr)">
                  {{ $t(addr.nameI18nKey) }}
                </li>
              </ul>
            </div>

            <div class="curr-clinic">
              <img class="curr-clinic__qrcode" :src="currClinic.qrcodeImg" />
              <p class="curr-clinic__name">{{ $t(currClinic.name) }}</p>
            </div>
          </div>

          <div class="contact-wrap">
            <p class="txt-dark_gray small">
              {{ $t('addLineFriendMethod1') }}
            </p>
            <p class="txt-dark_gray small">
              {{ $t('addLineFriendMethod2') }}
            </p>
          </div>

          <!-- <div class="qr_box">
            <div class="ivf_box">
              <a href="https://line.me/R/ti/p/%40232ecljp" target="_blank">
                <img src="@/assets/img/ivf_qrcode.png" />
                <h5 class="txt-theme">
                  禾馨宜蘊婦產科診所
                </h5>
              </a>
            </div>
            <div>
              <a href="https://line.me/R/ti/p/%40627jlyse" target="_blank">
                <img src="@/assets/img/tcm_qrcode.png" />
                <h5 class="txt-theme">
                  宜蘊中醫診所
                </h5>
              </a>
            </div>
          </div> -->
        </div>
      </template>

      <!-- <img v-show="socialLightbox.type === 0" src="@/assets/img/LINE@.jpg" /> -->
      <!-- <img v-show="socialLightbox.type === 1" src="@/assets/img/WhatsApp.jpg" />
      <img v-show="socialLightbox.type === 2" src="@/assets/img/weChat.jpg" /> -->
      <!-- <template > -->
      <div class="sms-info" v-show="socialLightbox.type === 1">
        <img class="sms-info__qrcode" alt="qrcode" :src="socialLightbox.qrcodeImg" />
        <div class="sms-info__detail">
          <p class="sms-info__detail__name">{{ socialLightbox.name }}</p>
          <p class="sms-info__detail__text">
            {{ $t('addLineFriendByWeChat') }}
          </p>
        </div>
      </div>

      <div class="sms-info" v-show="socialLightbox.type === 2">
        <img class="sms-info__qrcode" alt="qrcode" :src="socialLightbox.qrcodeImg" />
        <div class="sms-info__detail">
          <p class="sms-info__detail__name">{{ socialLightbox.name }}</p>
          <p class="sms-info__detail__text">
            {{ $t('addLineFriendByWhatsApp') }}
          </p>
        </div>
      </div>
      <div class="sms-info" v-show="socialLightbox.type === 3">
        <img class="sms-info__qrcode" alt="qrcode" :src="socialLightbox.qrcodeImg" />
        <div class="sms-info__detail">
          <p class="sms-info__detail__name">{{ socialLightbox.name }}</p>
          <p class="sms-info__detail__text">
            {{ $t('addLineFriendMethod1') }}
          </p>
          <p class="sms-info__detail__text">
            {{ $t('addLineFriendMethod2') }}
          </p>
        </div>
      </div>
      <!-- </template> -->
      <!-- <img v-show="socialLightbox.type === 3" src="@/assets/img/LINE@ivf.jpg" /> -->
      <!--<img v-show="socialLightbox.type === 4" src="@/assets/img/LINE@tcm.jpg" /> -->
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import clinicAddress from '@/config/clinicAddress.js';
import taipeiReproduce from '@/assets/clinicQrcode/taipeiReproduce.png';

// 重新排列 台北桃園台中順序
const addressReOrderMapping = {
  0: 0,
  1: 3,
  2: 1,
  3: 4,
  4: 2,
  5: 5
};

// console.log('clinicAddress', clinicAddress);

const clincAddressReOrder = clinicAddress.reduce((list, item, idx) => {
  list.splice(addressReOrderMapping[idx], 1, item);

  return list;
  // 刪除桃園中醫的QRcode
}, new Array(6)).filter(((item) => item.clinicKey !== 'taoyuanTcm'));

export default {
  name: 'SocialLightbox',
  data() {
    return {
      clinicAddress,
      clincAddressReOrder,
      currClinic: {
        name: 'traffic.taipeiIvf.name',
        qrcodeImg: taipeiReproduce
      }
    };
  },
  computed: {
    ...mapState(['socialLightbox', 'bodyLock'])
  },
  watch: {
    bodyLock(v) {
      const body = document.getElementsByTagName('body')[0];
      if (v) {
        body.classList.add('lock');
      } else {
        body.classList.remove('lock');
      }
    }
  },
  methods: {
    ...mapMutations(['setSocialLightbox', 'setBodylock']),
    clickCloseLightbox() {
      this.setSocialLightbox({ status: false });
      this.setBodylock(false);
    },
    handleClinic(data) {
      this.currClinic.name = data.nameI18nKey;
      this.currClinic.qrcodeImg = data.icons[1].funcArgs.qrcodeImg;
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/other/mixin.scss";
@import "@/assets/scss/other/color.scss";

.sms-info {
  width: 100%;
  min-width: 280px;
  max-width: 360px;
  background-color: #fef8f9;
  @include padding(50px 30px);
  @include flex(center, center, column);
  border-radius: 10px;
  background-image: url("~@/assets/img/bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  &__qrcode {
    width: 140px;
  }

  &__detail {
    margin-top: 30px;
    text-align: center;

    &__name {
      margin-bottom: 10px;
      @include font-style($color-theme, 20, 500, 1px);
    }

    &__text {
      @include font-style($c-text1, 14, 400, 1px, 28px);
    }
  }
}

.line-qrcode-list-wrap {
  @include flex(center);

  &--pc {
    .line-qrcode-list {
      width: 700px;
      margin-top: 0;
    }
  }
}

.line-qrcode-list {
  width: 300px;
  @include flex(left);
  flex-wrap: wrap;
  margin-top: 20px;

  &__item {
    flex: none;
    width: 33.333333%;
    @include font-style($c-text1, 16, 500, 1px, 30px);
    cursor: pointer;

    &--active {
      color: $color-theme;
    }
  }
}

.contact-wrap {
  margin-top: 30px;
}

.curr-clinic {
  @include flex(center, center, column);

  &--pc {

    .curr-clinic__name {
      margin-top: 10px;
      @include font-size(16);
    }

    // .curr-clinic__qrcode {
    //   margin-top: 10px;
    // }

  }

  &__qrcode {
    @include flex(center);
    margin-top: 10px;
  }

  &__name {
    margin-top: 20px;
    @include font-style($color-theme, 20, 500, 1px);
  }
}

.mobile-show {
  display: none;
}

@media screen and (max-width: 1023px) {
  .pc-show {
    display: none;
  }

  .mobile-show {
    display: block;
  }

  .sms-info {
    &__qrcode {
      width: 220px;
    }
  }
}
</style>
