var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{attrs:{"id":"news"}},[_c('div',{staticClass:"w1200 news-container"},[_c('div',{staticClass:"col35 intro"},[_c('h5',{staticClass:"txt-bold"},[_vm._v(" "+_vm._s(_vm.$t('news'))+" "),_c('img',{staticClass:"deco_flower",attrs:{"src":require("@/assets/img/deco_flower.svg"),"alt":""}})])]),_c('div',{staticClass:"col65"},[_c('ul',{staticClass:"news-list"},_vm._l((_vm.showNews),function(item,key){return _c('li',{key:key},[_c('router-link',{attrs:{"to":`${_vm.getUrlLangCode()}${item.link}`}},[_c('p',{staticClass:"date col10"},[_vm._v(_vm._s(item.date))]),_c('p',{staticClass:"type col25"},[_vm._v(_vm._s(item.type))]),_c('p',{staticClass:"context col70"},[_vm._v(_vm._s(item.context))])])],1)}),0),_c('router-link',{staticClass:"btn",attrs:{"to":{
        name: 'News',
        params: {
          tagSlug: 'all',
          page: '1'
        }
      }}},[_vm._v(_vm._s(_vm.$t('more')))])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }