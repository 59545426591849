<template>
  <footer id="dw">
    <div class="w1400">
      <div class="address-store-wrap">
        <div class="address-wrap">
          <div class="address-wrap__box">
            <footer-clinic-address :clinicData="clinicData" />
          </div>
        </div>
        <div class="store-wrap">
          <p class="store-wrap__desc">
            <span class="store-wrap__desc__text">{{ $t('downloadNuwaApp') }}</span>
            <span class="store-wrap__desc__text">{{ $t('enjoyNuwaService') }}</span>
          </p>
          <div class="store-icon-wrap">
            <a target="_blank" href="https://apps.apple.com/tw/app/id1558412392">
              <img class="store-icon-wrap__icon" alt="appstore" src="@/assets/icons/appstore_icon.svg" />
            </a>
          </div>
          <div class="store-icon-wrap">
            <a target="_blank" href="https://play.google.com/store/apps/details?id=com.nuwacare.app">
              <img class="store-icon-wrap__icon" alt="google-play" src="@/assets/icons/android_icon.svg" />
            </a>
          </div>
        </div>
      </div>
      <div class="other">
        <div class="copyright">
          <p class="copyright__text">
            ©2021 NUWA FERTILITY ALL RIGHTS RESERVED.
            <font-awesome-icon :icon="['far', 'plus-square']" class="h5 plus_square" @click="openLightbox1" />
          </p>
          <!-- 這邊是切換語言 -->
          <div class="lang-privacy-wrap">
            <!-- <div class="lang-dropdown">
              <ReDropdown :options="langConfig" v-model="langVal" @change="changeLang" />
            </div> -->
            <p class="privacy-text" @click="openLightbox2">
              {{ $t("privacyPolicy.title") }}
            </p>
          </div>
        </div>
        <div class="browser" style="border: none;">
          <p class="browser__text" style="padding-top:0;">{{ $t("browser") }}</p>
        </div>
      </div>

      <div id="privacy_lightbox2" v-show="ifLightbox1">
        <div class="cover" @click="closeLightbox1"></div>
        <div class="box">
          <img src="@/assets/img/delete.svg" class="close" @click="closeLightbox1" />
          <div class="privacy_box">
            <div class="txt-black p">
              {{ $t("reservedText") }}
            </div>
          </div>
        </div>
      </div>

      <PrivacyPopup v-show="ifLightbox2" @close="closeLightbox2" />
    </div>
  </footer>
</template>

<script>
import cusStorage from '@/utils/cusStorage';
import { isMobile } from 'mobile-device-detect';
import { mapMutations } from 'vuex';
import PrivacyPopup from '@/components/global/PrivacyPopup.vue';
import {
  selectClinics
} from '@/config/clinicAddress';
import FooterClinicAddress from '@/components/global/FooterClinicAddress.vue';
import { getLangCode, getUrlLangCode } from '@/utils/getLangCode';
// import ReDropdown from '@/components/global/dropdown/ReDropdown.vue';
import LangOption from '@/components/global/dropdown/LangOption.vue';

export default {
  name: 'Footer',
  components: {
    PrivacyPopup,
    FooterClinicAddress,
    // ReDropdown
  },
  data() {
    return {
      isMobile,
      ifLightbox1: false,
      ifLightbox2: false,
      langVal: '',
      langConfig: [
        {
          label: '繁體中文',
          value: 'zh-tw',
          render: () => LangOption,
          renderData: {
            label: '繁體中文'
          }
        },
        {
          label: '简体中文',
          value: 'zh-cn',
          render: () => LangOption,
          renderData: {
            label: '简体中文'
          }
        },
        {
          label: 'English',
          value: 'en',
          render: () => LangOption,
          renderData: {
            label: 'English'
          }
        },
        {
          label: '日本語',
          value: 'ja',
          render: () => LangOption,
          renderData: {
            label: '日本語'
          }
        }
      ],
      footerNav: [
        {
          title: [[this.$t('aboutNuwaPublic'), '/about/nuwa']],
          subTitle: [
            [this.$t('nuwaIvf'), '/about/ivf'],
            [this.$t('nuwaTcm'), '/about/tcm'],
            [this.$t('nuwaLabs'), '/about/lab']
          ]
        },
        {
          title: [[this.$t('aboutTreatment'), null]],
          subTitle: [
            [this.$t('ivfTreatment'), '/about/ivf'],
            [this.$t('tcmTreatment'), '/about/tcm']
          ]
        },
        {
          title: [[this.$t('internationalMedicalCenter'), '/international']],
          subTitle: [
            [this.$t('visa'), '/international'],
            [this.$t('contact'), 'ivfconsult@nuwacare.com']
          ]
        },
        {
          title: [[this.$t('nuwaStories'), '/story/1']]
        },
        {
          title: [[this.$t('news'), '/news/all/1']]
        }
      ]
    };
  },
  computed: {
    clinicData() {
      return selectClinics(
        [
          'taipeiIvf',
          'taipeiTcm',
          'taoyuanIvf',
          'taichungTcm',
          'taichungIvf',
        ]
      );
    }
  },
  methods: {
    ...mapMutations(['setSocialLightbox', 'setBodylock', 'setLanguage']),
    checkDeviceAndOpen(type, url) {
      if (this.isMobile) {
        window.open(url, '_blank');
      } else {
        const data = {
          status: true,
          type
        };
        this.setSocialLightbox(data);
        this.setBodylock(true);
      }
    },
    openLightbox1() {
      this.ifLightbox1 = true;
      const el = document.getElementsByTagName('body')[0];
      el.classList.add('lock');
    },
    closeLightbox1() {
      this.ifLightbox1 = false;
      const el = document.getElementsByTagName('body')[0];
      el.classList.remove('lock');
    },
    openLightbox2() {
      this.ifLightbox2 = true;
      const el = document.getElementsByTagName('body')[0];
      el.classList.add('lock');
    },
    closeLightbox2() {
      this.ifLightbox2 = false;
      const el = document.getElementsByTagName('body')[0];
      el.classList.remove('lock');
    },
    initLangDropdown() {
      // const toSelectLang = {
      //   'zh-TW': 'tw',
      //   'zh-CN': 'cn',
      //   en: 'en',
      //   ja: 'ja'
      // };

      this.langVal = getLangCode();
    },
    changeLang(lang) {
      // switchLanguage(lang);
      cusStorage.setItem('lang', lang);

      // 設完語言重整，會自動判斷語言重導
      const pagePath = window.location.pathname.replace(/^\/(zh-TW|zh-CN|en|ja)/, '');
      const targetPage = `${window.location.origin}/${getUrlLangCode()}${pagePath}`;
      window.location = targetPage;
    }
  },
  created() {
    this.initLangDropdown();
  },
  watch: {
    /** 換路徑即關閉footer lightbox */
    $route() {
      this.ifLightbox1 = false;
      this.ifLightbox2 = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.address-store-wrap {
  display: flex;
  width: 100%;
  box-sizing: border-box;
  padding: 50px 0;

  @media screen and (max-width: 1280px) {
    display: block;
  }
}

.address-wrap {
  flex: none;
  width: 66.666666%;
  display: flex;
  // margin: 0 -20px;
  flex-direction: column;

  @media screen and (max-width: 1280px) {
    width: 100%;
  }

  &__box {
    width: 100%;
    box-sizing: border-box;
    display: flex;

    @media screen and (max-width: 768px) {
      margin-top: 10px;
    }

    &+& {
      margin-top: 30px;

      @media screen and (max-width: 768px) {
        margin-top: 10px;
      }
    }

    @media screen and (max-width: 1280px) {
      flex: 1;
      width: auto;
    }
  }
}

.store-wrap {
  flex: none;
  width: 33.333333%;
  display: flex;
  align-items: center;
  flex-direction: column;

  @media screen and (max-width: 1280px) {
    align-items: flex-start;
    width: 100%;
  }

  &__desc {
    margin-top: 48px;

    &__text {
      display: block;
      width: 100%;
      color: #fff;
      font-size: 14px;
    }
  }
}

.links {
  li {
    display: inline-block;
    width: 100%;
  }
}

.lang-privacy-wrap {
  display: flex;
  align-items: center;
}

.lang-dropdown {
  // width: 100px;
  margin-right: 30px;
}

.privacy-text {
  color: rgba(#fff, 0.7);
  font-size: 14px;
  padding: 0;
}

.copyright {
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 768px) {
    display: block;
  }

  &__text {
    padding: 0;
    color: rgba(#fff, 0.7);
    font-size: 14px;
  }
}

.browser {
  margin-top: 5px;
  padding-bottom: 30px;

  &__text {
    padding: 0;
    color: rgba(#fff, 0.7);
    font-size: 14px;
  }
}

.other {
  border-top: 1px solid rgba(255, 255, 255, 0.4);
  padding-top: 30px;
}

.store-icon-wrap {
  width: 200px;
  margin-top: 20px;

  &__icon {
    width: 100%;
    vertical-align: bottom;
    cursor: pointer;
  }
}
</style>
