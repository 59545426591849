import VueCookies from 'vue-cookies';
import i18n from '@/i18n';
import { getLangCode, getApiLangCode } from '@/utils/getLangCode';
import { request as oriRequest } from './public';

// process?.env?.NODE_ENV
const isDev = process?.env?.NODE_ENV === 'development';

const API_URL = process.env.VUE_APP_API_NEW;

function request(data, successFunc, errorFunc) {
  const token = data.token ? VueCookies.get('api_token') : null;

  return oriRequest({
    baseURL: API_URL,
    ...data,
    token,
    // headers lang 開放時打開
    // headers: { lang: getApiLangCode() }
  })
    .then((res) => res.data)
    .then(({ status, message, result }) => {
      if (!status && typeof errorFunc === 'function') {
        errorFunc(message);
      }
      if (status && typeof successFunc === 'function') {
        successFunc(result, status);
      }
      return { status, message, result };
    })
    .then((res) => {
      if (process.env.NODE_ENV === 'development') {
        if (isDev) {
          // console.log('>> res', res);
        }
      }
      return res;
    })
    .catch((/* err */) => {
      if (typeof errorFunc === 'function') errorFunc('連線失敗');
    });
}

const setStorage = (key, data) => {
  localStorage.setItem(key, JSON.stringify(data));
};

// const getStorage = (key) => {
//   const data = localStorage.getItem(key);
//   return JSON.parse(data);
// };

export const setI18Nlang = (data) => {
  const lang = getApiLangCode();

  const lists = data.reduce(
    (res, { name, translate }) => ({
      ...res,
      [name]: translate?.[lang] ?? name,
    }),
    {}
  );
  const cookieLang = getLangCode();
  i18n.mergeLocaleMessage(cookieLang, lists);
};

/**
 * sysVars
 */
export async function getSysVars(successFunc = null) {
  // TODO 存快取
  const { status, result } = await request({
    url: 'api/vars/lists',
  });
  // 這邊取出後端給的預約天數 = 70天
  // const newRegisterDay = result.data[0].json.content;
  // console.log(newRegisterDay);
  if (!status || result?.data.length === 0) {
    return;
  }
  const data = result?.data.reduce(
    (arr, item) => ({
      ...arr,
      [item.keyword]: item.json,
    }),
    {}
  );

  successFunc({ data });
}
export async function getSysVars2() {
  const { status, result } = await request({
    url: 'api/vars/lists',
  });
  if (!status || result?.data.length === 0) {
    return { error: 'API returned no data.' };
  }
  const data = result?.data.reduce(
    (arr, item) => ({
      ...arr,
      [item.keyword]: item.json,
    }),
    {}
  );
  const reserveWeek = data.branch_update_days.content;
  return reserveWeek;
}
/**
 * Languages
 */
export async function initLanguages(next) {
  // 不快取，換語言時會吃到換語言前的資料
  localStorage.removeItem('languages');
  const { status, result } = await request({
    url: 'api/vars/languages',
  });
  if (!status || result?.data.length === 0) {
    return;
  }
  const lang = getApiLangCode();

  const lists = (result?.data || []).reduce((res, { keyword, content, translate }) => {
    if (!translate?.[lang]) {
      return res;
    }

    return {
      ...res,
      [keyword]: translate[lang],
      [content]: translate[lang],
    };
  }, {});

  // i18n.mergeLocaleMessage(getLangCode(), lists);

  setStorage('languages', lists);
  if (next) { next({ data: lists }); }
}

/**
 * 登入 (舊的)
 */
export function logIn(data, successFunc = null, errorFunc = null) {
  return request(
    {
      url: 'api/login',
      data,
      method: 'post',

    },
    successFunc,
    errorFunc
  );
}

/**
 * 登入 v2
 */
export function newLogIn(data, successFunc = null, errorFunc = null) {
  return request(
    {
      url: 'api/v2/login',
      data,
      method: 'post',

    },
    successFunc,
    errorFunc
  );
}

/**
 * 註冊
 */
export function signUp(data, successFunc = null, errorFunc = null) {
  return request(
    {
      url: 'api/register',
      method: 'post',
      data,
    },
    successFunc,
    errorFunc
  );
}

/**
 * 註冊 v2
 */
export function newSignUp(data, successFunc = null, errorFunc = null) {
  return request(
    {
      url: 'api/v2/register',
      data,
      method: 'post',
    },
    successFunc,
    errorFunc
  );
}

/**
 * 國籍列表
 */
export function nationalityList(data, successFunc = null, errorFunc = null) {
  return request(
    {
      url: 'api/vars/country',
      data,
      method: 'post',
    },
    successFunc,
    errorFunc
  );
}

/**
 * 時區
 */
export function timeZoneList(data, successFunc = null, errorFunc = null) {
  return request(
    {
      url: 'api/vars/timezones',
      data,
      method: 'post',
    },
    successFunc,
    errorFunc
  );
}

/**
 * 取得註冊驗證碼
 */
// eslint-disable-next-line no-unused-vars
export function getVerifyCode(data, successFunc = null, errorFunc = null) {
  return request(
    {
      url: 'api/login/sendVerifyCode',
      method: 'post',
      data,
    },
    successFunc,
    errorFunc
  );
}
/**
 * 修改密碼
 */
export function changePassword(data, successFunc = null, errorFunc = null) {
  return request(
    {
      url: 'api/users/changePassword',
      data,
      method: 'post',
      token: true,
    },
    successFunc,
    errorFunc
  );
}
/**
 * 忘記密碼
 */
export function resetPassword(data, successFunc = null, errorFunc = null) {
  return request(
    {
      // url: 'auth/forget',
      url: 'api/login/forget',
      method: 'post',
      data,
    },
    successFunc,
    errorFunc
  );
}
/**
 * 取得會員基本資料
 */
export function getMemberProfile(successFunc = null, errorFunc = null) {
  return request(
    {
      url: 'api/users/profile',
      method: 'get',
      token: true,
    },
    successFunc,
    errorFunc
  );
}

/**
 * 取得會員基本資料 v2
 */
export function getMemberProfileV2(successFunc = null, errorFunc = null) {
  return request(
    {
      url: 'api/v2/users',
      method: 'get',
      token: true,
    },
    successFunc,
    errorFunc
  );
}

/**
 * 網路掛號前檢驗會員資料
 */
export function checkMemberData(data, successFunc = null, errorFunc = null) {
  return request(
    {
      url: 'api/login/check',
      method: 'post',
      data,
    },
    successFunc,
    errorFunc
  );
}
// /**
//  * 取得最新總表
//  * @param clinic_id
//  * @param sussesFunc
//  * @param errorFunc
//  * @returns {*}
//  */
// export function getFirstSchedule(data, lang = null, sussesFunc = null, errorFunc = null) {
//   return request({
//     url: 'api/branchs',
//     method: 'get',
//     params: { clinic_id: data, language_id: lang },
//   })
//     .then((res) => {
//       if (res.data) {
//         sussesFunc(res);
//       } else {
//         errorFunc(res.data.message);
//       }
//     })
//     .catch();
// }

/**
 * 院所列表
 */
export async function getBranchs(successFunc, errorFunc) {
  // TODO 存快取
  const { status = false, message = '無法取得院所列表', result = {} } = await request({
    url: 'api/branchs',
    method: 'get',
  });
  if (!status) {
    errorFunc(message);
    return false;
  }

  const { data = [] } = result;
  setI18Nlang(data);

  const lang = getApiLangCode();
  const options = data.map(({ id, name, devisions_status, translate: langName, code, color, devisions, type }) => ({
    label: langName?.[lang] || name,
    id,
    value: code,
    clinic: devisions_status.map((i) => +i),
    color,
    devisions,
    devisions_status,
    // devisions: Object.keys(devisions)?.reduce((acc, cur) => {
    //   const imgUrl = devisions[cur];
    //   if (imgUrl) {
    //     acc.push(/^ http(s){ 0,}:\/\//.test(imgUrl) ? imgUrl : `${API_URL}${imgUrl}`);
    //   }
    //   return acc;
    // }, []),
    type,
  }));
  successFunc(options);

  return true;
}

/**
 * 醫師列表
 * @returns
 */
export async function getDoctors(params, successFunc, errorFunc) {
  const { status = false, message = '無法取得醫師列表', result = {} } = await request({
    url: 'api/branchs/doctors',
    method: 'get',
    params,
  });
  if (!status) {
    errorFunc(message);
    return false;
  }

  const { data = [] } = result;
  setI18Nlang(data);
  const lang = getApiLangCode();
  const lists = data
    .filter(({ status: sss }) => sss)
    .map(({ name, translate: langName, id, children, branch_id, devision, remark }) => {
      const child = [id, ...children.map(({ id: docid }) => docid)];
      const childBranchs = children.filter(({ status: s }) => s).map(({ branch_id: bid }) => bid);
      const branchs = [branch_id, ...childBranchs].filter((item) => item);
      return {
        label: langName?.[lang] || name,
        // value: id,
        value: child.join(','),
        child,
        branchs,
        devision,
        remark,
      };
    });
  successFunc(lists);
  return true;
}

/**
 * 醫生廣告
 */
export async function getDoctorAds(successFunc) {
  return request(
    {
      url: 'api/appointment-ads/lists',
      method: 'get',
      params: { lang_id: getApiLangCode() },
    },
    successFunc
  );
}

/**
 * 排班表
 */
export async function getSchedules(params, successFunc, errorFunc) {
  return request(
    {
      url: 'api/branchs/schedules',
      method: 'get',
      params,
    },
    successFunc,
    errorFunc
  );
}

/**
 * 網路掛號
 */
export function appointment(data, successFunc = null, errorFunc = null) {
  return request(
    {
      url: 'api/appointment',
      method: 'post',
      token: true,
      data,
    },
    successFunc,
    errorFunc
  );
}

/**
 * 取得會員網路掛號
 */
export function checkAppointment(successFunc = null, errorFunc = null) {
  return request(
    {
      url: 'api/appointment',
      token: true,
    },
    successFunc,
    errorFunc
  );
}
/**
 * 取消掛號
 */
export function cancelAppointment(data, successFunc = null, errorFunc = null) {
  return request(
    {
      url: 'api/appointment/cancel',
      method: 'post',
      data,
      token: true,
    },
    successFunc,
    errorFunc
  );
}
/**
 * 取得國家代碼
 */
export function getCountryCode() {
  return request({
    url: 'api/country/v2',
    method: 'get',
  }).then(({ result }) => result?.data || []);
}

/**
 * 取得圖形驗證碼
 */
export function getCaptchaImg(successFunc) {
  return request(
    {
      url: 'api/captcha/getCaptchaImg',
      method: 'get',
    },
    successFunc
  );
}

/**
 * 驗證圖形驗證嗎
 */
export function captchaVerifyCode(data, successFunc = null, errorFunc = null) {
  return request(
    {
      url: 'api/captcha/verifyCode',
      method: 'post',
      data,
    },
    successFunc,
    errorFunc
  );
}

/**
 * 取得首頁輪播最新5則
 * @param successFunc
 * @param errorFunc
 * @returns {*}
 */
export function getBanners(lang_id, successFunc = null, errorFunc = null) {
  // TODO 存快取
  return request(
    {
      url: 'api/banners',
      method: 'get',
      params: { lang_id },
    },
    successFunc,
    errorFunc
  );
}

/**
 * 取得文章
 * procreations
 * labs
 * traditionals
 * teams_content
 * internationals
 * documents
 * locations
 */
export function getArticles(params, successFunc = null, errorFunc = null) {
  // TODO 存快取
  return request(
    {
      url: 'api/articles/lists',
      method: 'get',
      params: {
        ...params,
        type: ['traditionals', 'procreations', 'labs', 'teams_content', 'documents', 'internationals', 'locations', 'introduction'],
      },
      // : { lang_id: data, type: 'procreations' },
    },
    successFunc,
    errorFunc
  );
}
// 拿取第一筆服務中心slug
export function getServeList(params, successFunc = null, errorFunc = null) {
  // TODO 存快取
  return request(
    {
      url: 'api/articles/lists',
      method: 'get',
      params: {
        ...params,
        type: ['procreations'],
      },
      // : { lang_id: data, type: 'procreations' },
    },
    successFunc,
    errorFunc
  );
}
/**
 * 取得分頁資料
 */
export function getArticlePages(params, successFunc = null, errorFunc = null) {
  return request(
    {
      url: 'api/articles',
      method: 'get',
      params, // : { lang_id: data, type: 'procreations', page: 1 },
    },
    successFunc,
    errorFunc
  );
}

/**
 * 取得指定生殖療程內容
 */
export function getArticleView(params, successFunc = null, errorFunc = null) {
  return request(
    {
      url: 'api/articles/view',
      method: 'get',
      params, // : { lang_id: data, slug: 'baby2' },
    },
    successFunc,
    errorFunc
  );
}

export function getSysArticleView(params, successFunc = null, errorFunc = null) {
  return request(
    {
      url: 'sys/articles/view',
      method: 'get',
      params, // : { lang_id: data, slug: 'baby2' },
    },
    successFunc,
    errorFunc
  );
}

/**
 * 取得全部分類
 */
export function getCategories(params, successFunc = null, errorFunc = null) {
  return request(
    {
      url: 'api/categories/lists',
      method: 'get',
      params, // : { lang_id: data, slug: 'baby2' },
    },
    successFunc,
    errorFunc
  );
}

/**
 * 取得醫療團隊的團隊分類
 */
export function getTeamTypes(lang = null, successFunc = null, errorFunc = null) {
  return request(
    {
      url: 'api/teams/types',
      method: 'get',
      params: { lang_id: lang },
    },
    successFunc,
    errorFunc
  );
}

/**
 * 取得特定分類下所有的團隊成員
 */
export function getTeams(lang = null, successFunc = null, errorFunc = null) {
  return request(
    {
      url: 'api/teams',
      method: 'get',
      params: { lang_id: lang },
    },
    successFunc,
    errorFunc
  );
}

/**
 * 取得metas
 */
export function getMetas(lang_id = null, successFunc) {
  return request(
    {
      url: 'api/vars/metas',
      params: { lang_id },
    },
    successFunc
  );
}

/**
 * 取得codes
 */
export function getCodes(successFunc) {
  return request(
    {
      url: 'api/vars/codes',
    },
    successFunc
  );
}

/**
 * 舊會員 同意條款
 */
export function agreePrivacy(data, successFunc) {
  return request(
    {
      url: 'api/v2/users/agreePrivicy',
      data,
      method: 'post',
      token: true,
    },
    successFunc
  );
}

/**
 * 同步個資
 */
export function syncMemberData(successFunc) {
  return request(
    {
      url: 'api/v2/users/sync',
      token: true,
    },
    successFunc
  );
}

/**
 * Email驗證
 */
export function verifyEmail(data, successFunc, errorFunc) {
  return request(
    {
      url: 'api/v2/users/sendVerify/email',
      data,
      token: true,
      method: 'post'
    },
    successFunc,
    errorFunc
  );
}

/**
 * 忘記密碼發信 v2
 */
export function forgetPassword(data, successFunc = null, errorFunc = null) {
  return request(
    {
      // url: 'auth/forget',
      url: 'api/v2/forget',
      method: 'post',
      data,
    },
    successFunc,
    errorFunc
  );
}

/**
 * 忘記密碼重設密碼 v2
 */
export function resetPasswordFromForgetPassword(data, successFunc = null, errorFunc = null) {
  const { password, token } = data;
  return request(
    {
      url: 'api/v2/users/resetPassword',
      method: 'post',
      data: {
        password,
      },
      headers: {
        Authorization: `Bearer ${token}`
      }

    },
    successFunc,
    errorFunc
  );
}

/**
 * 手機驗證
 */
export function verifyPhone(data, successFunc, errorFunc) {
  return request(
    {
      url: 'api/v2/users/sendVerify/phone',
      data,
      token: true,
      method: 'post'
    },
    successFunc,
    errorFunc
  );
}

/**
 * 簡訊驗證碼驗證
 */
export function verifySmsCode(data, successFunc, errorFunc) {
  return request(
    {
      url: 'api/v2/users/verifyPhone',
      data,
      token: true,
      method: 'post'
    },
    successFunc,
    errorFunc
  );
}

/**
 * email 驗證
 */
export function verifyEmailV2(data, successFunc, errorFunc) {
  return request(
    {
      url: 'api/v2/users/sendVerify/email',
      data,
      token: true,
      method: 'post'
    },
    successFunc,
    errorFunc
  );
}

/**
 * email 驗證碼驗證
 */

export function verifyEmailCodeV2(data, successFunc, errorFunc) {
  return request(
    {
      url: 'api/v2/users/verifyEmail',
      data,
      token: true,
      method: 'post'
    },
    successFunc,
    errorFunc
  );
}

/**
 * email 門診總表
 */

export function getFullSchedule(params, successFunc, errorFunc) {
  return request(
    {
      url: 'api/outpatient-timetables',
      params,
      token: true,
      method: 'get'
    },
    successFunc,
    errorFunc
  );
}
