<template>
  <div class="c-privacy">
    <div class="c-privacy__content">
      <div class="warning-wrap">

        <div class="warning-wrap__content" @scroll="handleScroll" ref="warningContent">
        <div v-if="apiI18nMessage && apiI18nMessage.privacyPolicy" v-html="apiI18nMessage.privacyPolicy">
        </div>
        </div>
      </div>

      <div class="btn-wrap">
        <re-button
          round="round"
          type="border"
          @click="handleAgreePrivacy"
          :disabled="disabled"
          :style="{ width: '120px' }"
        >
          {{$t('agree')}}
        </re-button>
      </div>
    </div>

    <!-- <re-dialog
      :visible.sync="visible"
      footerPosition="center"
      :template="Unverified"
      :data="dialogData"
    /> -->
  </div>
</template>
<script>
import ReButton from '@/components/form/ReButton.vue';
import { agreePrivacy, getMemberProfileV2 } from '@/lib/cakeHttp';
import { mapMutations, mapState } from 'vuex';
// import ReDialog from '@/components/dialog/ReDialog.vue';
// import Unverified from '@/components/dialog/content/Unverified.vue';

export default {
  name: 'Privacy',
  components: {
    ReButton,
    // ReDialog
  },
  data() {
    return {
      disabled: false,
      // Unverified,
      // visible: false,
      // dialogData: {
      //   type: 'email'
      // }
    };
  },
  computed: {
    ...mapState(['apiI18nMessage']),
  },
  methods: {
    ...mapMutations(['logIn',
    // 'toggleShowVerifyWarning'
  ]),
    close() {
      this.$emit('beforeClose');
    },
    getMemberData() {
      getMemberProfileV2(
        (data) => {
          // login 結構與直接拿結構不同，所以多套一層
          this.logIn({
            member: data
          });

          // 過渡版 不驗證
          // const { email_verified_at } = data;

          // if (!email_verified_at) {
          //   this.toggleShowVerifyWarning({ status: true, type: 'email' });
          //   this.$router.push({ name: 'MemberData' });
          //   this.close();
          //   return;
          // }

          this.close();
        },
        () => {}
      );
    },
    handleAgreePrivacy() {
      agreePrivacy({ agree: !this.disabled }, () => {
        // 更新會員資料
        this.getMemberData();
      });
    },
    handleScroll() {
      // const currentScroll = this.$refs.warningContent.scrollTop; // 已经被卷掉的高度
      // const clientHeight = this.$refs.warningContent.offsetHeight; // 容器高度
      // const { scrollHeight } = this.$refs.warningContent; // 内容总高度

      // if ((currentScroll + clientHeight) > (scrollHeight - 20)) {
      //   this.disabled = false;
      // }
    },

  },
  mounted() {

  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/other/mixin.scss";
@import "@/assets/scss/other/color.scss";

.c-privacy {
  @include padding(30px);
}

.btn-wrap {
  margin-top: 30px;
  @include flex(center);
}

.warning-wrap {
  @include flex(center, center, column);
  height: 50vh;

  &__content {
    height: 100%;
    overflow: auto;
  }
}

.paragraph-title {
  margin-top: 30px;
}

.warning {
  @include padding(0 0 20px);

  &__icon {
    width: 50px;
  }
}

.desc {
  &__text {
    @include font-style($c-black, 16, 400, 1px, 24px);
  }
}
</style>
