<template>
  <div>
    <div class="header_area" :style="{ height: headerAreaHeight }"></div>
    <header id="header" :class="{ normal: !indexHeader, isfixed: isFixed }" ref="header">
      <div class="container">
        <div class="logo">
          <router-link :to="{ name: 'HomeWithLang' }">
            <img v-if="getLanguage === 'en'" class="color En" src="@/assets/img/logo-en.svg" alt="logo" />
            <img v-else-if="getLanguage === 'zh-cn'" class="color Cn" src="@/assets/img/logo-cn.svg" alt="logo" />
            <img v-else class="color tw" src="@/assets/img/logo.svg" alt="logo" />
            <img v-if="getLanguage === 'en'" class="w" src="@/assets/img/logo-w-en.svg" alt="logo" />
            <img v-else-if="getLanguage === 'zh-cn'" class="w" src="@/assets/img/logo-w-cn.svg" alt="logo" />
            <img v-else class="w tw" src="@/assets/img/logo-w.svg" alt="logo" />
          </router-link>
        </div>
        <div class="navbar" :class="{ active: isHamburgerActive }">
          <img src="@/assets/img/x.svg" alt="" class="hamberger_x" @click="isHamburgerActive = !isHamburgerActive" />
          <ul class="navbar-list" style="line-height:2.4em">

            <li :class="[getLanguage === 'en' ? 'liEn' : '']" @click="
              toPage({
                name: 'ClinicIntro'
              })
            ">
              {{ $t("clinicIntro") }}
            </li>
            <li :class="[getLanguage === 'en' ? 'liEn' : '']" @click="
              toPage({
                name: 'AboutNuwa'
              })
            ">
              {{ $t("aboutNuwaHeader") }}
            </li>
            <li :class="[getLanguage === 'en' ? 'liEn' : '']" @click="
              toPage({
                name: 'Staff',
                params: {
                  classification: 'procreations',
                  typeId: 'p1'
                }
              })
            ">
              {{ $t("nuwaTeamsHeader") }}
            </li>
            <li :class="[getLanguage === 'en' ? 'liEn' : '']" @click="
              toPage({
                name: 'Story',
                params: {
                  page: 'p1'
                }
              })
            ">
              {{ $t("nuwaStoriesHeader") }}
            </li>
            <li :class="[getLanguage === 'en' ? 'liEn' : '']" @click="
              toPage({
                name: 'AboutInfo',
                params: {
                  classification: 'ivf',
                  articleId: firstServerSlug,

                },
                query: {
                  sub:0
                }
              })
            ">
              {{ $t("serveCenter") }}
            </li>
            <li :class="[getLanguage === 'en' ? 'liEn' : '']" @click="
              toPage({
                name: 'News',
                params: {
                  tagSlug: 'all',
                  page: '1'
                }
              })
            ">
              {{ $t("news") }}
            </li>
            <li :class="[getLanguage === 'en' ? 'liEn' : '']" @click="
              toPage({
                name: 'Schedule',
              })
            ">
              {{ $t("schedule") }}
            </li>
          </ul>
          <ul class="navbar-btns" style="line-height:2.4em">
            <li class="isMobile pointer" :class="[getLanguage === 'en' ? 'liEn' : '']" @click="toBooking">
              {{ $t("booking") }}
            </li>

            <template v-if="!getMemberAll.api_token">

              <li class="pointer" :class="[getLanguage === 'en' ? 'liEn' : '']" @click="toPage({ name: 'Login' })">
                {{ $t("login") }}
              </li>
            </template>
            <template v-if="getMemberAll.api_token">

              <li class="isMobile pointer" :class="[getLanguage === 'en' ? 'liEn' : '']" @click="toMyReservation">
                {{ $t("myregistration") }}
              </li>
              <li class="pointer" :class="[getLanguage === 'en' ? 'liEn' : '']" @click="toPage({ name: 'MemberHome' })">
                {{ $t("account.memberCenter") }}
              </li>
              <li class="divide-line" :class="[getLanguage === 'en' ? 'liEn' : '']">
                |
              </li>

              <li :class="[getLanguage === 'en' ? 'liEn' : '']">
                <p class="user-name-logout">
                  <!-- <span class="user-name">{{ getMemberAll.name | formatName }}</span>
                  <span>, </span> -->
                  <span class="pointer" style="cursor: pointer;" @click="clickLogOut()">{{ $t("logout") }}</span>
                </p>
              </li>
              <!-- <li class="isMobile pointer" :class="[getLanguage === 'en' ? 'liEn' : '']">
                <div class="lang-privacy-wrap1">
                  <div class="lang-dropdown1">
                      <ReDropdown :options="langConfig" v-model="langVal" @change="changeLang" />
                  </div>
                </div>
              </li> -->
            </template>
            <li class="isMobile pointer" :class="[getLanguage === 'en' ? 'liEn' : '']">
                <div class="lang-privacy-wrap1">
                  <div class="lang-dropdown1">
                      <ReDropdown :options="langConfig" v-model="langVal" @change="changeLang" />
                  </div>
                </div>
            </li>
          </ul>
        </div>
         <!-- 變更切換語言位置 -->
        <div class="lang-privacy-wrap">
          <div class="lang-dropdown">
              <ReDropdown :options="langConfig" v-model="langVal" @change="changeLang" />
            </div>
        </div>
        <div class="hamburger">
          <div id="hamburger" @click="isHamburgerActive = !isHamburgerActive">
            <div></div>
          </div>
        </div>
      </div>
    </header>

  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex';
import { getLangCode, getUrlLangCode } from '@/utils/getLangCode';
// import ReDialog from '@/components/dialog/ReDialog.vue';
// import Unverified from '@/components/dialog/content/Unverified.vue';
import ReDropdown from '@/components/global/dropdown/ReDropdown.vue';
import LangOption from '@/components/global/dropdown/LangOption.vue';
import cusStorage from '@/utils/cusStorage';

export default {
  name: 'Header',
  props: ['indexHeader', 'firstServerSlug'],
  // components: {
  //   ReDialog
  // },
  data() {
    return {
      // Unverified,
      // visible: false,
      scrollDistence: window.scrollY,
      screenHeight: window.innerHeight,
      headerAreaHeight: '',
      isFixed: false,
      isHamburgerActive: false,
      langConfig: [
        {
          label: '繁體中文',
          value: 'zh-tw',
          render: () => LangOption,
          renderData: {
            label: '繁體中文'
          }
        },
        {
          label: '简体中文',
          value: 'zh-cn',
          render: () => LangOption,
          renderData: {
            label: '简体中文'
          }
        },
        {
          label: 'English',
          value: 'en',
          render: () => LangOption,
          renderData: {
            label: 'English'
          }
        },
        {
          label: '日本語',
          value: 'ja',
          render: () => LangOption,
          renderData: {
            label: '日本語'
          }
        }
      ],
      langVal: '',
      // dialogData: {
      //   type: 'email'
      // }
    };
  },
  filters: {
    formatName(val) {
      if (!val) {
        return '';
      }

      const isEnglishName = /^[a-zA-Z\s]+$/.test(val);

      // // 英文取第一個字
      if (isEnglishName && val !== null) {
        const [firstWord] = val.split(' ');

        return firstWord;
      }

      // 中文若小於等於四個字直接顯示
      if (val.length < 5) {
        return val;
      }

      // 中文若大於四個字，顯示前四個字 + ...
      return `${val.substring(0, 4)}...`;
    }
  },
  methods: {
    ...mapMutations([
      'logOut',
      // 'toggleShowVerifyWarning',
      'toggleShowPrivacyWarning',
      'toggleShowMsg',
      'setMsgInfo'
    ]),
    toLogin() {
      this.$router.push({
        name: 'Login'
      });
    },
    listenScrollDistence() {
      this.scrollDistence = window.scrollY;
    },
    // openUnverifiedWarning() {
    //   this.visible = true;
    // },
    clickLogOut() {
      this.logOut();
      this.$router.push({ name: 'Home' });
    },
    toPage(routeInfo) {
      if (this.$route.path === routeInfo) return;
      this.isHamburgerActive = false;

      console.log('routeInfo', routeInfo);
      this.$router.push(routeInfo);
    },
    toBooking() {
      this.toPage({ name: 'Registration' });
    },
    toMyReservation() {
      this.toPage({ name: 'MemberHome' });
    },
    changeLang(lang) {
      // switchLanguage(lang);
      cusStorage.setItem('lang', lang);

      // 設完語言重整，會自動判斷語言重導
      const pagePath = window.location.pathname.replace(/^\/(zh-TW|zh-CN|en|ja)/, '');
      const targetPage = `${window.location.origin}/${getUrlLangCode()}${pagePath}`;
      window.location = targetPage;
    },
    initLangDropdown() {
      // const toSelectLang = {
      //   'zh-TW': 'tw',
      //   'zh-CN': 'cn',
      //   en: 'en',
      //   ja: 'ja'
      // };

      this.langVal = getLangCode();
      console.log(this.langVal);
    }
  },
  computed: {
    ...mapState(['memberInfo']),
    ...mapGetters(['getMemberAll', 'getLanguage'])
  },
  watch: {
    scrollDistence() {
      if (this.scrollDistence >= this.screenHeight) {
        this.isFixed = true;
      } else {
        this.isFixed = false;
      }
    }
  },
  created() {
    window.addEventListener('scroll', this.listenScrollDistence, true);
    this.initLangDropdown();
  },
  mounted() {
    this.headerAreaHeight = `${this.$refs.header.clientHeight}px`;
  },
  updated() {
    this.headerAreaHeight = `${this.$refs.header.clientHeight}px`;
  },
  destroyed() {
    window.removeEventListener('scroll', this.listenScrollDistence, true);
  },
  components: { ReDropdown }
};
</script>

<style scoped lang="scss">

@media screen and (min-width: 1366px) {
  .isMobile {
    display: none;
  }
}
@media screen and (max-width: 1366px){
  .lang-privacy-wrap{
    display: none;
  }
}
.lang-dropdown {
  margin-left: 30px;
  width: 120px;
}

</style>
