<template>
  <div class="re-expand-container"
  :class="{
    're-expand-container--position-top': positionTop
  }"
  v-show="visible">
    <div class="re-expand-container__content" :class="`re-expand-container__content--bg--${bg}`">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { directive as onClickaway } from 'vue-clickaway';

export default {
  name: 'ReExpandContainer',
  directives: {
    onClickaway
  },
  props: {
    visible: {
      type: Boolean,
      default: true
    },
    bg: {
      type: String,
      default: 'white'
    },
    positionTop: {
      type: Boolean,
      default: false
    }
  },
  // methods: {
  //   handleVisible() {
  //     this.$emit('update:visible', false);
  //   }
  // }
};
</script>

<style lang="scss">
@import "@/assets/scss/other/mixin.scss";
@import "@/assets/scss/other/color.scss";

.re-expand-container {
  width: 100%;
  @media screen and (max-width: 1366px) {
    width: 50%;
  }
  @include position(tl, calc(100%), 0);
  z-index: 100;

  &--position-top {
    // 改變位置要變成bl 原本是tl
    @include position(bl, 0, 0);
    transform: translateY(-100%);
  }

  &__content {
    margin-top: 5px;
    box-sizing: border-box;
    border-radius: 8px;
    height: auto;
    max-height: 300px;
    overflow: auto;
    position: relative;
    @media screen and (max-width: 1366px) {
      height: 150px;
      .re-dropdown__option__content__list {
      display: flex;
      flex-direction: column;
    }
    }

    &::-webkit-scrollbar {
      width: 4px;
      @include position(tr, 0, 5px);
      border-radius: 10px;
      overflow: hidden;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $color-theme;
    }

    &::-webkit-scrollbar-track {
      background: #ededed;
    }

    &--bg--transparent {
      background-color: transparent;
    }

    &--bg--white {
      background-color: $c-white;
    }
  }
}

</style>
