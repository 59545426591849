<template>
  <div class="home">
    <IndexKv />
    <IndexAbout />
    <IndexBaby />
    <IndexDoctors />
    <IndexNews />
    <IndexImgLink />
  </div>
</template>

<script>
// @ is an alias to /src
import '@/assets/scss/index.scss';
import IndexKv from '@/components/index/IndexKv.vue';
import IndexAbout from '@/components/index/IndexAbout.vue';
import IndexBaby from '@/components/index/IndexBaby.vue';
import IndexDoctors from '@/components/index/IndexDoctors.vue';
import IndexNews from '@/components/index/IndexNews.vue';
import IndexImgLink from '@/components/index/IndexImgLink.vue';
// import getUrlLangCode from '@/lib/getUrlLangCode.js';

export default {
  name: 'Home',
  components: {
    IndexKv,
    IndexAbout,
    IndexBaby,
    IndexDoctors,
    IndexNews,
    IndexImgLink
  },
  methods: {
    toAppStore() {
      const { hash } = this.$route;
      console.log('hash', hash);
      // const scrollEle = document.querySelector('#app');

      if (hash === '#dw') {
        // 無效，有空研究一下
        // this.$router.push({
        //   name: 'HomeWithLang',
        //   hash: '#dw'
        // });

        const element = document.querySelector('#dw');
        element.scrollIntoView();
      }
    }
  },
  created() {
    this.$router.push({
      name: 'HomeWithLang',
      hash: this.$route.hash || ''
    });
  },

  mounted() {
    this.toAppStore();
  }
};
</script>
