import Vue from 'vue';
import VueRouter from 'vue-router';
import { getUrlLangCode, initLanguage } from '@/utils/getLangCode';

import Home from '../views/Home.vue';

Vue.use(VueRouter);
initLanguage();

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

const routes = [
  {
    path: '*', // 當連到不是我們自訂義的路由時
    redirect: '/', // 重新指向路由回首頁
  },
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/{lang}',
    name: 'HomeWithLang',
    component: () => import('@/views/Home.vue'),
  },
  {
    path: '/Privacy',
    name: 'Privacy',
    component: () => import('@/views/Privacy.vue'),
  },
  {
    path: '/TechnicalSupport',
    name: 'TechnicalSupport',
    component: () => import('@/views/TechnicalSupport.vue'),
  },

  // {
  //   path: '/about/ivf',
  //   name: 'AboutIvf',
  //   component: () => import('@/views/AboutIvf.vue'),
  // },
  // {
  //   path: '/about/lab',
  //   name: 'AboutLab',
  //   component: () => import('@/views/AboutLab.vue'),
  // },
  // {
  //   path: '/about/tcm',
  //   name: 'AboutTcm',
  //   component: () => import('@/views/AboutTcm.vue'),
  // },
  {
    path: '/clinicintro',
    name: 'ClinicIntro',
    component: () => import('@/views/ClinicIntro.vue'),
  },
  {
    path: '/clinicintro/:uuid',
    name: 'ClinicIntroDetail',
    component: () => import('@/views/ClinicIntroDetail.vue'),
  },
  {
    path: '/about/nuwa',
    name: 'AboutNuwa',
    component: () => import('@/views/AboutNuwa.vue'),
  },
  {
    path: '/about/:classification',
    name: 'AboutType',
    component: () => import('@/views/AboutType.vue'),
  },
  {
    path: '/aboutinfo/:classification/:articleId',
    name: 'AboutInfo',
    props: true,
    component: () => import('@/views/AboutInfo.vue'),
  },
  // {
  //   path: '/about/ivf',
  //   name: 'AboutIvf',
  //   component: () => import('../views/AboutIvf.vue'),
  // },
  // {
  //   path: '/about/lab',
  //   name: 'AboutLab',
  //   component: () => import('../views/AboutLab.vue'),
  // },
  // {
  //   path: '/about/tcm',
  //   name: 'AboutTcm',
  //   component: () => import('../views/AboutTcm.vue'),
  // },
  {
    path: '/staff/:classification/:typeId/:teamId?',
    name: 'Staff',
    props: true,
    component: () => import('@/views/Staff.vue'),
  },
  {
    path: '/story/:page',
    name: 'Story',
    props: true,
    component: () => import('@/views/Story.vue'),
  },
  {
    path: '/storyarticle/:articleId',
    name: 'StoryArticle',
    props: true,
    component: () => import('@/views/StoryArticle.vue'),
  },
  {
    path: '/international',
    name: 'International',
    component: () => import('@/views/International.vue'),
  },
  {
    path: '/news/:tagSlug/:page',
    name: 'News',
    props: true,
    component: () => import('@/views/News.vue'),
  },
  {
    path: '/newsarticle/:articleId',
    name: 'NewsArticle',
    props: true,
    component: () => import('@/views/NewsArticle.vue'),
  },
  {
    path: '/schedule',
    name: 'Schedule',
    props: true,
    component: () => import('@/views/Schedule.vue'),
  },
  {
    path: '/registration',
    name: 'Registration',
    component: () => import('@/views/Registration.vue'),
  },
  {
    path: '/registrationchoose/:classification',
    name: 'RegistrationChoose',
    props: true,
    component: () => import('@/views/RegistrationChoose.vue'),
  },
  {
    path: '/registrationconfirm',
    name: 'RegistrationConfirm',
    component: () => import('@/views/RegistrationConfirm.vue'),
  },
  {
    path: '/registrationcomplete',
    name: 'RegistrationComplete',
    component: () => import('@/views/RegistrationComplete.vue'),
  },
  // {
  //   path: '/login',
  //   name: 'Login',
  //   component: () => import('@/views/MemberLogin.vue'),
  // },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/NewLogin.vue'),
  },
  // {
  //   path: '/signup',
  //   name: 'Signup',
  //   component: () => import('@/views/MemberSignup.vue'),
  // },
  {
    path: '/signup',
    name: 'Signup',
    component: () => import('@/views/NewSignup.vue'),
  },
  // {
  //   path: '/forgetpassword',
  //   name: 'ForgetPassword',
  //   component: () => import('@/views/MemberForgetPassword.vue'),
  // },
  {
    path: '/forgetpassword',
    name: 'ForgetPassword',
    component: () => import('@/views/NewForgetPassword.vue'),
  },
  // {
  //   path: '/resetpassword',
  //   name: 'resetpassword',
  //   component: () => import('@/views/ResetPassword.vue'),
  // },
  {
    path: '/validate',
    name: 'Validate',
    component: () => import('@/views/Validate.vue'),
  },
  {
    path: '/validateemail',
    name: 'ValidateEmail',
    component: () => import('@/views/ValidateEmail.vue'),
  },
  {
    path: '/resetpassword',
    name: 'Resetpassword',
    component: () => import('@/views/ResetPassword.vue'),
  },

  {
    path: '/memberhome',
    name: 'MemberHome',
    component: () => import('@/views/MemberHome.vue'),
  },
  {
    path: '/memberdata',
    name: 'MemberData',
    component: () => import('@/views/MemberData.vue'),
  },
  {
    path: '/doctorIntro/:teamSlug/:slug',
    name: 'doctorIntro',
    props: true,
    component: () => import('@/views/DoctorIntro.vue'),
  },
  // ===============================================
  // 後台預覽編輯
  {
    path: '/storyarticle/preview/:articleId',
    name: 'StoryArticlePreview',
    props: true,
    component: () => import('@/views/StoryArticle.vue'),
  },
  {
    path: '/aboutinfo/preview/:classification/:articleId',
    name: 'AboutInfoPreview',
    props: true,
    component: () => import('@/views/AboutInfo.vue'),
  },
  {
    path: '/newsarticle/preview/:articleId',
    name: 'newsPreview',
    props: true,
    component: () => import('@/views/NewsArticle.vue'),
  },

  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '@/views/About.vue'),
  // },
];

const routesLanguageAddon = (routeList) => routeList.map((r) => {
  if (r.path.includes('{lang}')) {
    return { ...r, path: r.path.replace('{lang}', getUrlLangCode()) };
  }

  if (r.path !== '/' && r.path !== '*') {
    return { ...r, path: `/${getUrlLangCode()}${r.path}` };
  }

  return r;
});

const routeWithLang = routesLanguageAddon(routes);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  linkActiveClass: 'active',
  routes: routeWithLang,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth',
      };
    }
    if (to.name === from.name && to.path === from.path) {
      // tab 切換 與 select 選擇 replace query url 不動作
      return null;
    }
    if (savedPosition) {
      return savedPosition;
    }
    return { x: 0, y: 0 };
  },
});

// console.log('router.getRoutes', router.getRoutes);

router.beforeEach(async (to, from, next) => {
  const el = document.getElementsByTagName('body')[0];
  el.classList.remove('lock');
  next();
});

export default router;
