<template>
  <div class="re-dropdown" :style="{ 'max-width': `${maxWidth}` }">
    <div class="re-dropdown__box" @click="expandOptions" v-on-clickaway="closeOptions">
      <p class="re-dropdown__box__selected-value">
        <font-awesome-icon icon="globe" class="globe-icon" />
        {{ selectedValue }}
      </p>
      <div class="expand-down" :class="{ 'expand-down--active': expandConfig.status }">
        <img class="expand-down__icon" alt="" src="@/assets/icons/dropdown-arrow__white.svg" />
      </div>
    </div>
    <re-expand-container :visible.sync="expandConfig.status" positionTop>
      <div class="re-dropdown__option__content">
        <ul class="re-dropdown__option__content__list">
          <re-dropdown-option
            v-for="opt of formatOptions"
            :key="opt.value"
            v-bind="opt"
            :currOption="value"
            @handleOption="handleOption"
          />
        </ul>
      </div>
    </re-expand-container>
  </div>
</template>

<script>
import { directive as onClickaway } from 'vue-clickaway';
import triggerValidate from '@/mixins/triggerValidate';
import ReDropdownOption from '@/components/global/dropdown/ReDropdownOption.vue';
import ReExpandContainer from '@/components/form/ReExpandContainer.vue';

export default {
  name: 'ReSelect',
  mixins: [triggerValidate],
  components: {
    ReDropdownOption,
    ReExpandContainer
  },
  inject: {
    reFormItem: {
      default: ''
    }
  },
  directives: {
    onClickaway
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    value: {
      default: ''
    },
    options: {
      type: Array,
      default: () => []
    },
    maxWidth: {
      type: String,
      default: 'auto'
    },
    formatLabelFn: {
      type: [Function, null],
      default: null
    }
  },
  data() {
    return {
      expandConfig: {
        use: true,
        status: false
      }
    };
  },
  computed: {
    selectedValue() {
      const valueMappingObj = this.formatOptions.reduce((obj, ele) => {
        obj[ele.value] = ele.label;
        return obj;
      }, {});
      if (this.formatLabel) {
        this.formatLabel();
      }

      return valueMappingObj[this.value];
    },
    formatOptions() {
      const allOpts = this.options.reduce((optionsList, item) => {
        // 傳進來是群組時，把該 label 和 選項攤平，如何渲染是 SelectOption 的事
        if (item.options) {
          return [
            ...optionsList,
            {
              // type: 'group' 用來讓下面的 SelectOption 判斷是否為群組標籤
              type: 'label',
              label: item.label
            },
            ...item.options
          ];
        }

        return [...optionsList, item];
      }, []);
      // allOpts.splice(0, 0, {
      //   value: '-',
      //   label: this.$t('select'),
      //   disabled: true
      // });

      return allOpts;
    },
    test() {
      return JSON.stringify(this.expandConfig);
    }
  },
  methods: {
    closeOptions() {
      this.expandConfig.status = false;
    },
    expandOptions() {
      this.$set(this.expandConfig, 'status', !this.expandConfig.status);
    },
    handleOption(value) {
      this.$emit('input', value);
      this.$emit('change', value);
      this.triggerValidate('change', value);
      this.closeOptions();
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/other/mixin.scss";
@import "@/assets/scss/other/color.scss";

.re-dropdown {
  position: relative;
  width: 100%;

  &__box {
    &__selected-value {
      position: relative;
      width: 100%;
      cursor: pointer;
      font-size: 14px;
      padding: 0;
      padding-right: 40px;
    }
  }

  &__option {
    @include position(tl, 100%, 0);
    z-index: 100;
    margin-top: 5px;
    background-color: $c-white;
    border: 1px solid $c-main;
    border-radius: 4px;
    overflow: hidden;

    &__content {
      position: relative;
    }
  }
}

.expand-down {
  @include position(tr, 50%, 10px);
  transform: translateY(-50%) rotate(180deg);
  transition: 0.4s;

  &__icon {
    width: 15px;
  }

  &--active {
    transform: translateY(-50%) rotate(360deg);
  }
}
</style>
