<template>
  <section id="kv">
    <swiper :options="swiperOption"
      ref="mySwiper"
      class="kv"
      v-if="banners.length">
      <swiper-slide :style="{backgroundImage: `url(${defaultKv})`}"></swiper-slide>
      <swiper-slide v-for="(item, key) in banners"
        :key="`${key}${item.path}`"
        :style="{backgroundImage: `url(${item.pic})`}">
        <div class="swiper-content">
          <div class="w1200">
            <h4 class="eng"
              v-html="item.zhSlogan">
            </h4>
            <a :href="item.path"
              class="btn"
              v-if="item.path">{{$t('lookingMore')}}</a>
          </div>
        </div>
      </swiper-slide>
      <div class="swiper-pagination"
        slot="pagination"></div>
    </swiper>
    <swiper :options="swiperOption"
      ref="myMobileSwiper"
      class="kv_mobile">
      <swiper-slide :style="{backgroundImage: `url(${defaultMobileKv})`}"></swiper-slide>
      <swiper-slide v-for="(item, key) in banners"
        :key="`${key}${item.path}`"
        :style="{backgroundImage: `url(${item.pic_mb})`}">
        <div class="swiper-content">
          <div class="w1200">
            <h4 class="eng"
              v-html="item.zhSlogan">
            </h4>
            <!-- <br> -->
            <!--
            <h6 class="txt-medium">
              <span v-html="item.enSlogan"></span>
              <img class="deco_flower" src="@/assets/img/deco_flower.svg" alt="">
            </h6>
            -->
            <a :href="item.path"
              class="btn"
              :class="{ 'noText': item.zhSlogan === null || item.zhSlogan === '' }"
              v-if="item.path">{{$t('lookingMore')}}</a>
            <!-- <router-link :to="item.path" class="btn">了解更多</router-link> -->
          </div>
        </div>
      </swiper-slide>
      <div class="swiper-pagination"
        slot="pagination"></div>
    </swiper>
  </section>
</template>

<script>
import { getBanners } from '@/lib/cakeHttp';
import { mapMutations, mapState } from 'vuex';

// import kv1 from '@/assets/img/temp/banner_1.jpg';
// import kv2 from '@/assets/img/temp/banner_2.jpg';
// import kv3 from '@/assets/img/temp/banner_3.jpg';

// import kv1Mobile from '@/assets/img/temp/banner_1_mobile.jpg';
// import kv2Mobile from '@/assets/img/temp/banner_2_mobile.jpg';
// import kv3Mobile from '@/assets/img/temp/banner_3_mobile.jpg';

export default {
  name: 'IndexKv',
  data() {
    return {
      swiperOption: {
        autoplay: true,
        loop: true,
        slidesPerView: 1,
        spaceBetween: 0,
        allowTouchMove: true,
        pagination: {
          el: '#kv .swiper-pagination',
          clickable: true
        },
      },
      kv: [],
      defaultKv: '/defaultKv.gif',
      defaultMobileKv: '/defaultMobileKv.gif'
    };
  },
  computed: {
    ...mapState(['lang', 'banners'])
    // ...mapGetters(['getLangUUID'])
  },
  methods: {
    ...mapMutations({
      commitData: 'setData'
    }),
    async getData() {
  // --------------------------------------------------------
      // let kv = [];
      // kv.push({
      //   path: '',
      //   pic: this.defaultKv,
      //   pic_mb: this.defaultMobileKv,
      //   zhSlogan: '',
      // });
      // this.commitData(['banners', kv]);
  // --------------------------------------------------------
      await getBanners(this.lang, ({ data }) => {
        const kv = (data || []).map((banner) => ({
          path: banner?.url || '',
          pic: banner?.web,
          pic_mb: banner?.mobile,
          zhSlogan: (banner?.title || '').replace(/\r\n/gi, '<br />')
        }));
        this.commitData(['banners', kv]);
      });
    },
    updateSlides() {
      this.$refs.mySwiper.swiper.removeSlide(0);
      this.$refs.myMobileSwiper.swiper.removeSlide(0);
    },
  },
  watch: {
    lang(val) {
      if (val !== null) {
        this.getData();
      }
    }
  },
  async mounted() {
    await this.getData();
    this.updateSlides();
  }
};
</script>
