var render = function render(){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"re-button",class:[
    `re-button--${_vm.type}`,
    `re-button--size--${_vm.size}`,
    `re-button--round--${_vm.round}`,
    {
      're-button--disabled': _vm.disabled || _vm.isLoading
    }
  ],style:(_vm.setExtraStyle),attrs:{"disabled":_vm.disabled || _vm.isLoading},on:{"click":_vm.handleClick}},[(_vm.$slots.prefix)?_c('span',{staticClass:"re-button__prefix"},[_vm._t("prefix")],2):_vm._e(),_c('span',{staticClass:"re-button__content",class:[{ 're-button__content--circle': _vm.extra === 'circle' }]},[_vm._t("default")],2),(_vm.$slots.suffix)?_c('span',{staticClass:"re-button__suffix"},[_vm._t("suffix")],2):_vm._e(),(_vm.isLoading)?_c('span',{staticClass:"re-button__loading"},[_c('span',{staticClass:"re-button__loading__icon",class:{
        're-button__loading__icon--main-color': ['border', 'plain'].includes(_vm.type)
      }})]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }