<template>
  <section id="imgLink">
    <a href="mailto:ivfconsult@nuwacare.com" class="col50 imgLink-container">
      <div
      class="imgLink-img"
      :style="{backgroundImage: `url(${require('@/assets/img/index/imglink_1.jpg')})`}"></div>
      <div class="imgLink-cover"></div>
      <div class="imgLink-context">
        <h5 class="imgLink-context-title eng">
          {{$t('oneForOneService')}}
        </h5>
        <h6>one-on-one client service</h6>
        <div class="btn btn-solid">{{$t('lookingMore')}}</div>
      </div>
    </a>
    <router-link :to="{name: 'International'}" class="col50 imgLink-container">
      <div
      class="imgLink-img"
      :style="{backgroundImage: `url(${require('@/assets/img/index/imglink_2.jpg')})`}"></div>
      <div class="imgLink-cover"></div>
      <div class="imgLink-context">
        <h5 class="imgLink-context-title eng">
          {{$t('visa')}}
        </h5>
        <h6>Entry Permit Application</h6>
        <div class="btn btn-solid">{{$t('lookingMore')}}</div>
      </div>
    </router-link>
  </section>
</template>

<script>
export default {
  name: 'IndexImgLink',
  data() {
    return {};
  },
};
</script>
